import axios from 'axios'
import {PhotoService} from '@/services'
import {Converter} from '@/helpers'

async function upStatus(status){
    var photos = []
    if(status.photos.length>0){
        photos = await PhotoService.uploadPhotos(status.photos)
    }
    return axios.post("status/", {content: status.content,  photos: photos.map(photo=> photo.id)}).then(response=>{
        return Converter.convertUserStatus(response.data)
    })
}

function getStatus(statusId){
    return axios.get(`status/${statusId}/`).then(res=>{
        return Converter.convertUserStatus(res.data)
    })
}

function deleteStatus(statusId){
    return axios.delete(`status/${statusId}/`)
}

export const StatusService = {
    upStatus,
    getStatus,
    deleteStatus
}