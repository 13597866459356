<template>
  <v-card class="mb-2" flat outlined >
    <user-box :user="review.creator">
      <template v-slot:after-subtitle>{{review.createdDate|formatCreatedDate}}</template>
    </user-box>
    <div  @click="goDetail">
      <v-img v-if="coverImage" :src="coverImage" max-height="300"></v-img>
      <v-card-title>{{review.title}}</v-card-title>
    
    <!-- <v-card-text class="content">
        <span v-html="sanitizedContent.substring(0,100)"></span>
        <span v-if="sanitizedContent.length >100">...</span>
    </v-card-text> -->
    </div>
    <v-divider></v-divider>

    <v-card-actions>
      <div class="mx-auto action" v-bind:class="review.liked===1?'liked': ''">
        <v-btn
          :disabled="!loggedIn"
          icon
          v-bind:class="review.liked===1?'liked': ''"
          @click="like"
        >
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <span class="counter">{{review.like}}</span>
      </div>
      
      <div class="mx-auto action" >
        <v-btn :disabled="!loggedIn" icon @click="openCommentBox">
          <v-icon>mdi-comment</v-icon>
        </v-btn>
        <span class="counter">{{review.comment}}</span>
      </div>
    </v-card-actions>

    <comment-box v-model="quickcomment" @send="sendComment" :show="openQuickCommentBox" :height="3"></comment-box>
  </v-card>
</template>

<script>
import SmallUserBox from "@/components/SmallUserBox.vue";
import {authComputed } from "@/helpers";
import {ReactionService, CommentService} from '@/services'
import Commentbox from '@/components/CommentBox.vue'
import sanitizeHtml from 'sanitize-html'

import moment from 'moment'
const MINUTE = 60
const HOUR = MINUTE*60
const DAY = HOUR*24

function getFirstImg(content){
   let rex = /<img[^>]+src="?([^"\s]+)"?[^>]*>/g;
   let imgsUrl = rex.exec(content)
   if(!imgsUrl){
     return null
   }
   return imgsUrl[1]
}


export default {
  props: {
    review: {
      type: Object,
      required: true,
      default: ()=>({
        id: null,
        createdDate: null,
        updatedDate: null, 
        content: '',
        title: '', 

        comment: 0,
        like: 0,
        liked: -1,

        creator: {
            username: "",
            firstname: "",
            lastname: "",
            avatar: null,
            level: ""
        }
      })
    },
  },
  data: () => {
    return {
      openQuickCommentBox: false,
      quickcomment: "",
    };
  },
  computed: {
    ...authComputed,
    sanitizedContent(){
      return sanitizeHtml(this.review.content)
    },
    coverImage(){
      return getFirstImg(this.review.content)
    }
  },
  components: {
    "user-box": SmallUserBox,
    "comment-box": Commentbox,
  },
  filters:{
       formatCreatedDate(date){
           let from = moment(new Date(date))
            let now = moment(new Date())
            
            let diffInSecond = now.diff(from, "seconds")

            let inDay = Math.floor(diffInSecond/DAY)
            if(inDay>=1){
               return new Date(date).toLocaleDateString('vi-VN')
            }
            let inHour = Math.floor(diffInSecond/HOUR)
            if(inHour>=1){
                return inHour+' giờ trước'
            }
            let inMinute = Math.floor(diffInSecond/MINUTE)
            if(inMinute>=1){
                return inMinute+' phút trước'
            }
            return 'Vừa xong'
       }
  },
  methods: {
    like() {
      this.review.liked = this.review.liked * -1;
      
      if(this.review.liked==-1){
        ReactionService.unlikeReview(this.review.id).then(()=>{
            this.review.like-=1
        })
      }else{
        ReactionService.likeReview(this.review.id).then(()=>{
            this.review.like+=1
        })
      }
    },
    openCommentBox() {
      this.openQuickCommentBox = !this.openQuickCommentBox;
    },
    sendComment() {
      CommentService.commentForReview(this.review.id, this.quickcomment).then(()=>{
        this.openQuickCommentBox = false;
        this.review.comment+=1
      })
    },
    goDetail(){
      this.$router.push(`review-detail/${this.review.id}`)
    }
  },
};
</script>
<style scoped>
.content p{
  /* text-align: justify;
  text-justify: inter-word; */
  word-break: break-all;
  white-space: pre-wrap;
}
.detail:hover{
    cursor: pointer;
}

</style>