<template>
    <v-form
      ref="form"
      v-model="valid"
    >
        <v-text-field v-if="!isResetPassMode"
            v-model="currentPass"
            :append-icon="showCurrentPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showCurrentPass ? 'text' : 'password'"
            label="Mật khẩu hiện tại"
            tabindex="1"
            autofocus
            @click:append="showCurrentPass = !showCurrentPass"
        ></v-text-field>

        <v-text-field
            v-model="password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.max]"
            :type="showPass ? 'text' : 'password'"
            label="Mật khẩu mới"
            hint="ít nhất 8 ký tự"
            tabindex="2"
            @click:append="showPass = !showPass"
        ></v-text-field>
        <v-text-field
            v-model="confirmPassword"
            :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showConfirmPass ? 'text' : 'password'"
            label="Xác nhận mật khẩu mới"
            hint="ít nhất 8 ký tự"
            tabindex="3"
            @click:append="showConfirmPass = !showConfirmPass"
        ></v-text-field>

         <v-card-text>
            <span class="red--text" v-show="isConfirmPassMatch">Mật khẩu của bạn không khớp nhau</span>
            <span class="red--text">{{error}}</span>
            <span class="green--text">{{msg}}</span>
          </v-card-text>
      <v-btn
        :disabled="!valid || this.error.length!=0"
        class="mr-4"
        tabindex="4"
        @click="send"
      >
        Lưu
      </v-btn>
    </v-form>
</template>

<script>
  import {UserService} from '@/services'

  export default {
    data: () => ({
      isResetPassMode: false,
      error: '',
      msg:'',
      valid: true,
      password: '',
      confirmPassword: '',
      currentPass: '',
      showPass: false,
      showConfirmPass: false,
      showCurrentPass: false,
      rules: {
          required: value => !!value || 'Hãy nhập mật khẩu của bạn.',
          min: v => v.length >= 8 || 'ít nhất 8 ký tự',
          max: v=> v.length<=100 || "Mật khẩu của bạn quá dài để nhớ"
      },
    }),
    computed:{
      isConfirmPassMatch(){
        return this.confirmPassword && (this.password !== this.confirmPassword)
      },
    },
    watch:{
      password: function (){
        if(this.error && this.error.length >0){
          this.error = ''
        }
      }
    },
    mounted(){
      if(this.$route.params.token){
        this.isResetPassMode = true
      }
    },
    methods: {
      send () {
        this.$refs.form.validate()
        if(!this.valid || this.isConfirmPassMatch){
          return
        }
        if(this.$route.params.token){//access from link email to reset password
          UserService.resetPassword(this.$route.params.token, this.password.trim()).then(()=>{
            this.$router.push('/')
          },error=>{
            this.error = error
          })
        }else{//login user change password
          UserService.changePassword(this.currentPass.trim(), this.password.trim()).then(()=>{
            this.msg="Thiết lập mật khẩu thành công. Hãy đăng nhập lại"
            setTimeout(()=>{this.$store.dispatch("user/logout")},1000)
          },error=>{
            let errors = error.response.data.user
            this.error = ''
            for(let key in errors){
              this.error += errors[key]['password']
            }
          })
        }
      },
    //   reset () {
    //     this.$refs.form.reset()
    //   },
    //   resetValidation () {
    //     this.$refs.form.resetValidation()
    //   },
    },
  }
</script>

<style scoped>

</style>