<template>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import {ClassicEditor} from '@/helpers'

    export default {
        props:{
            value: {
                type: String,
                require: true,
                default: ()=> ""
            }
        },
        data() {
            return {
                editorData: '',
                editor: ClassicEditor,
                editorConfig: {

                }
            }
        },
        watch:{
            editorData: function(val){
                this.$emit("input", val)
            },
            value: function(val){
                 this.editorData = val
            }
        },
        components: {
            // Use the <ckeditor> component in this view.
            ckeditor: CKEditor.component
        },
    }
</script>

<style lang="scss" scoped>

</style>