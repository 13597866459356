<template>
<v-row>
    <v-spacer></v-spacer>
    <v-col lg="6" xl="6">
      <v-sheet class="pa-2 fill-height">
        <div> 
            <div class="d-inline-block">
                <user-box class="pl-0" :user="review.creator"></user-box>
            </div>
            <div class="owner-menu d-inline-block float-right" v-if="isOwner">
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                 <v-btn text class="w-100" @click="edit">
                                   Sửa
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                 <v-btn text class="w-100" @click="deleteReview">
                                   Xóa
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div>
            <h3>{{review.title}}</h3>
            <p v-html="sanitizedContent" class="content ck-content"></p>
            <v-divider></v-divider>

            <v-card-actions>
                <div class="mx-auto action" v-bind:class="review.liked===1?'liked': ''">
                    <v-btn
                        :disabled="!loggedIn"
                        icon
                        v-bind:class="review.liked===1?'liked': ''"
                        @click="like"
                    >
                        <v-icon>mdi-heart</v-icon>
                    </v-btn>
                    <span class="counter">{{review.like}}</span>
                </div>
                <div  class="mx-auto action">
                    <v-btn :disabled="!loggedIn" icon @click="openCommentBox">
                        <v-icon>mdi-comment</v-icon>
                    </v-btn>
                    <span class="counter">{{review.comment}}</span>
                </div>
                <div class="mx-auto action">
                    <v-btn @click="shareFace" icon>
                        <v-icon>mdi-share</v-icon>
                    </v-btn>
                </div>
            </v-card-actions>
            <v-divider></v-divider>

            <comment-box v-model="quickcomment" @send="sendComment" :show="openQuickCommentBox" :height="3"></comment-box>
            
        </div>
        <comment-list :comments="review.comments" :targetId="review.id+''" @hasnewreply="hasNewReply"></comment-list>
      </v-sheet>
    </v-col>
    <v-spacer></v-spacer>
    
</v-row>
</template>
<script>
    import CommentList from '@/components/views/schedule-detail/CommentList.vue'
    import SmallUserBox from '@/components/SmallUserBox.vue'
    import {CommentService, ReactionService} from '@/services'
    import {authComputed} from '@/helpers'
    import Commentbox from '@/components/CommentBox.vue'
    import {ReviewService} from '@/services'
    // import {sanitilizeHtml} from '@/helpers'
    

    export default {
        props: {
            id: {
                type: [String, Number],
                required: true
            },
            data: {
                type: Object, 
                required: true
            }
        },
        data: ()=>({
            openQuickCommentBox: false,
            quickcomment: "",

            review:{
                id: null,
                createdDate: null,
                updatedDate: null, 
                content: '',
                title: '', 

                comment: 0,
                like: 0,
                liked: -1,

                comments:{},

                creator: {
                    username: "",
                    firstname: "",
                    lastname: "",
                    avatar: {},
                    level: ""
                }
            }
        }),
        computed:{
            ...authComputed,
            isOwner(){
                return this.loggedInUser.username==this.review.creator.username
            },
            sanitizedContent(){
                // return sanitilizeHtml(this.review.content)
                return this.review.content
            }
        },
        components:{
            'comment-list': CommentList,
            'user-box': SmallUserBox,
            "comment-box": Commentbox,
        },
        mounted(){
            Object.assign(this.review,this.$route.params.data)
        },
        methods:{
            like() {
                this.review.liked = this.review.liked * -1;
                
                if(this.review.liked==-1){
                    ReactionService.unlikeReview(this.review.id).then(()=>{
                        this.review.like-=1
                    })
                }else{
                    ReactionService.likeReview(this.review.id).then(()=>{
                        this.review.like+=1
                    })
                }
            },
            hasNewReply(){
                this.review.comment+=1
            },
            openCommentBox() {
                this.openQuickCommentBox = !this.openQuickCommentBox;
            },
            sendComment() {
                CommentService.commentForReview(this.review.id, this.quickcomment).then((res)=>{
                    this.openQuickCommentBox = false;
                    this.review.comment+=1
                    this.review.comments.itemsInPage.push(res)
                })
            },
            edit(){
                this.$router.push(`/review/${this.review.id}`)
            },
            deleteReview(){
                ReviewService.deleteReview(this.review.id).then(()=>{
                    this.$router.push("/")
                })
            },
            shareFace(){
                /*global FB*/
                let url = process.env.BASE_URL+"review-detail/"+this.review.id
                FB.ui({
                    method: 'share',
                    href: url,
                    }, function(){});
            }
        },
       
        beforeRouteEnter (to, from, next) {
            let id = to.params.id
            ReviewService.readReview(id).then(data=>{
                to.params.data = data
                next()
            })
        },
        beforeRouteUpdate (to, from, next) {
            let id = to.params.id
            ReviewService.readReview(id).then(data=>{
                to.params.data = data
                next()
            })
    
        },
    }
</script>

<style lang="scss" scoped>
.content{
  /* text-align: justify;
  text-justify: inter-word; */
  word-break: break-all;
  white-space: pre-wrap;
}
</style>