export const Converter= {
    convertComment,
    convertUser,
    convertCommentPage,
    convertImage,
    convertOverallScheduleInfo,
    convertUserStatus,
    convertReview

}

function convertImage(img){
    return {
        src: img.photo.full_size,
        id: img.id,
        thumb: img.photo.thumbnail,
        thumb_sm: img.photo.thumbnail_small,
        thumb_md: img.photo.thumbnail_medium,
        album: null
    }
}

function convertComment(input){
    return {
        id: input.id,
        user: convertUser(input.user),
        create_date: input.create_date,
        update_date: input.update_date,
        content: input.content,
        target_id: input.target_id,
        reply_for: input.reply_for,
        liked: input.is_liked ? 1:-1,
        like: input.total_likes||0,
        replies: {
            hasNext: false,
            itemsInPage: [],
            page: 1, 
            count: 0
        },
    }
}

function convertUser(input){
    return {
        firstname : input.first_name || "Phượt thủ ẩn danh",
        lastname : input.last_name || "",
        birthday : new Date(input.dob),
        level : input.experience|| "",
        avatar : input.avatar&&Object.keys(input.avatar).length>0?
                            convertImage({photo:input.avatar}) : 
                            {
                                src: require('@/assets/default_avatar.jpg'), 
                                thumb: require('@/assets/default_avatar.jpg'),
                                thumb_sm: require('@/assets/default_avatar.jpg'),
                                thumb_md: require('@/assets/default_avatar.jpg')
                            },
        coverPicture : input.cover_image&&Object.keys(input.cover_image).length>0?
                            convertImage({photo: input.cover_image}): 
                            {
                                src: require('@/assets/default_corver.jpg'), 
                                thumb: require('@/assets/default_corver.jpg'),
                                thumb_sm: require('@/assets/default_corver.jpg'),
                                thumb_md: require('@/assets/default_corver.jpg')
                            },
        username : input.user?input.user.username: input.username,
        email: input.user?input.user.email: input.email,
        joinedDate : new Date(input.user?input.user.create_date: input.create_date),
        hasNewNoti : false,
        bio : input.bio
    }
}

function convertCommentPage(input){
    
    let comments =  input.results.sort((a,b)=> a.create_date - b.create_date).map((item)=>{
        let out = convertComment(item)

        if(item.replies){
            out.replies = {
                hasNext: item.replies.next,
                itemsInPage:item.replies.results.sort((a,b)=> a.create_date - b.create_date).
                                                map((reply)=>convertComment(reply)),
                page: item.replies.page, 
                count: item.replies.count
            }
        }
        
        return out
    })
    return {
        hasNext: input.next,
        itemsInPage: comments,
        page: input.page, 
        count: input.count
    }

}

function convertOverallScheduleInfo(input) {
    return {
        schedule: {
        id: input.id,
        createdDate: input.create_date,
        startDate: new Date(input.plan_start_date),
        endDate: new Date(input.plan_end_date),
        content: input.description,
        startPoint: input.start_point,
        endPoint: input.end_point,
        name: input.name,
        images: input.schedule_photos.map(image=>convertImage(image)),
        allowAttachment: input.target_follower_number,
        allowDriver: input.target_driver_number,
        budget: input.fee,
        status: input.status,
        challengeLevel: input.difficult_level,
        vehicleType: input.vehicle_type,
        comment:input.total_comments,
        like:input.total_likes,
        follow:input.total_follows,
        total_member: input.total_members,
        total_requesting: input.total_requesters

      },
      creator: convertUser(input.create_by),
      liked: input.is_liked ? 1: -1,
      followed: input.is_followed ? 1: -1,

      type: "schedule"
    }
}

function convertUserStatus(status){
    return {
        type: "status",
        id: status.id,
        content: status.content, 
        createdDate: status.create_date,
        photos: status.photos.map(photo=>convertImage(photo)),
        comments: status.comments && Object.keys(status.comments).length>=0?convertCommentPage(status.comments):{
            hasNext: false,
            itemsInPage: [],
            page: 1, 
            count: 0
        },

        creator: convertUser(status.user),

        comment: status.total_comments||0,
        like: status.total_likes||0,
        liked: status.is_liked?1:-1,
    }
}


function convertReview(input){
    return {
        id: input.id, 
        title: input.title,
        content: input.content,
        createdDate: new Date(input.create_date),
        updatedDate: new Date(input.update_date),
        creator: convertUser(input.user),

        comments: input.comments && Object.keys(input.comments).length>0?convertCommentPage(input.comments): {
            hasNext: false,
            itemsInPage: [],
            page: 1, 
            count: 0
        },
        comment: input.total_comments||0,
        like: input.total_likes||0,
        liked: input.is_liked?1:-1,

        isPublic: input.is_published,
        type: "review"

    }
}