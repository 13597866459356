<template>
  <v-row>
  <v-col cols="8" class="ma-auto">
    <v-sheet class="pa-2">
      <div class="about">
        <h1>Đôi nét về chúng tôi</h1>
        <p>Trong quá trình tham gia một số group phượt trên facebook chúng tôi những lập trình viên mê phượt cảm thấy có nhiều điều bất tiện. Nên chúng tôi đã tạo ra trang web này với mục tiêu cố gắng khắc phục những những nhược điểm đó để giúp những người mê phượt có thể dễ dàng tìm đoàn, chia sẻ địa điểm, cập nhật tin tức một cách thuận tiện và nhanh chóng. Vì trang web đang trong quá trình xây dựng nên còn rất nhiều điểm hạn chế và chưa tốt vì vậy chúng tôi rất mong nhận được sự góp ý kiến của các bạn.
        </p>
        <p>
          Đối với chúng tôi mọi ý kiến của các bạn đều đáng quý, nó sẽ giúp trang web ngày một tốt hơn. Tuy nhiên, chúng tôi hy vọng ý kiến của các bạn Không chỉ dừng lại ở mức phát hiện lỗi mà còn bao có cả những chức năng mong muốn, những chức năng mới mà nếu hệ thống có thì sẽ tuyệt vời hơn. Hay chỉ đơn giản là tâm sự của những người mê phượt. 
        Vì dự án không có nguồn tài trợ và đội ngũ phát triển là tập hợp của những người cùng đam mê nên có thể những tính năng mới sẽ không thể ra mắt trong ngày một ngày hai, rất mong các bạn hiểu cho chúng tôi. 
        </p>
        <p>Các bạn có thể gửi ý kiến của mình theo 2 cách là: <br/>
        1. Gửi mail trực tiếp cho chúng tôi theo địa chỉ: <b>mexedich.feedback@gmail.com</b> <br/>
        2. Gửi trực tiếp từ box bên dưới</p>
        <v-textarea outlined auto-grow v-model="feedback"></v-textarea>
      </div>
    </v-sheet>
    <div class="d-flex justify-center mt-2">
      <v-btn  color="success" @click="sendFeedback" :disabled="feedback.length<=0">Gửi</v-btn>
    </div>
  </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      feedback: '',
      loading: false,
    }
  },
  methods:{
    sendFeedback(){
      if (this.loading) return
      if(this.feedback.length<=0) return;

      this.loading = true

      axios.post("user/feedback", {feedback: this.feedback}).then(()=>{
        this.feedback = ''
        alert("Chúng tôi chân thành cảm ơn ý kiến đóng góp của bạn")
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
p{
  text-align:left
}
</style>