<template>
    <v-row class="white-background">
        <v-col cols="12" v-if="!isLoading&&images.length==0">
            <span>Xin lỗi, chúng tôi không tìm thấy ảnh nào</span>
        </v-col>
        <v-col
            cols="12"
            v-if="$route.query.showBackBtn"
        >
            <v-btn fab dark small color="grey" class="mx-3" @click="goBack">
                <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
        </v-col>
        <v-col class="d-flex content px-0">
            <template v-for="column in 3">
                <v-col cols="4" cols-md="6" cols-sm="12" class="column" :key="column">
                    <template v-for="(i,index) in images">
                        <template v-if="$route.query.enablePickingPhoto">
                            <div
                                v-if="i.column===column"
                                :key="i.id"
                                style="position: relative"
                                @click="pickImage(i)"
                            >
                                <v-btn
                                    absolute
                                    icon
                                    dark
                                    x-large
                                    style="right: 0"
                                >
                                    <v-icon>{{ getIcon(i.isPicked) }}</v-icon>
                                </v-btn>
                                <img
                                    class="img-hover-effect"
                                    :src="i.src"
                                >
                            </div>
                        </template>
                        <template v-else>
                            <img v-if="i.column===column" :src="i.src" :key="i.id" @click="showImage(index)">
                        </template>
                    </template>
                </v-col>
            </template>
        </v-col>
        <LightBox
            ref="lightbox"
            :media="images"
            :show-caption="false"
            :show-light-box="false"
        />
    </v-row>
</template>

<script>
import LightBox from '@/components/imglightbox/LightBox.vue'
import {PhotoService} from '@/services'
import {authComputed} from '@/helpers'

// require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
    props:{
        username: {
            type:String,
            required: true,
        },
    },
    data(){
        return {
            isLoading: false,
            images:[],
            page:1,
            hasMore: true,
            lastImageInCol: 1,
            albumId: null,
        }
    },
    computed: {
        ...authComputed,
    },
    components:{
        LightBox,
    },
    mounted(){
        this.images = [];
        this.albumId = this.$route.params.albumId
        this.loadPhotos()
        document.addEventListener('wheel', this.loadMore)
        document.addEventListener('scroll', this.loadMore)
    },
    destroyed(){
        document.removeEventListener('wheel',this.loadMore)
        document.removeEventListener('scroll',this.loadMore)
    },
    methods:{
        showImage(index){
            this.$refs.lightbox.showImage(index)
        },
        loadMore(){
            if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.isLoading && this.hasMore) {
                this.loadPhotos()
            }
        },
        loadPhotos(){
            this.isLoading=true
            return PhotoService.getPhotoOfAlbum(this.username, this.albumId, this.page)
            .then(response=>{

                this.page+=1
                this.hasMore = response.hasNext
                let column= this.lastImageInCol;
                for( var image of response.photos){
                    let imgObj = {
                        src:image.src,
                        thumb:image.src,
                        column: column,
                        id:image.id
                    }
                    if (this.$route.query.enablePickingPhoto) {
                        let pickedImgIds = this.$route.query.pickedImgIds
                        imgObj.isPicked = pickedImgIds.includes(image.id)
                    }

                    this.images.push(imgObj)

                    if(column<3){
                        column+=1;
                    }else{
                        column=1
                    }
                }
                this.lastImageInCol = column
                this.$emit("loaded", response.count)
            })
            .catch(() => {

            }).then(()=>{
                this.isLoading=false;
            });
        },
        goBack () {
            this.$router.push({
                name: 'schedulePhotoGallery',
                params: {
                    username: this.loggedInUser.username
                },
                query: {
                    showBackBtn: true,
                    enablePickingPhoto: true,
                    pickedImgIds: this.$route.query.pickedImgIds
                }
            })
        },
        getIcon (isPicked) {
            if (isPicked) return 'mdi-checkbox-marked-circle-outline'
            return 'mdi-checkbox-blank-circle-outline'
        },
        pickImage (img) {
            if (img.isPicked) {
                img.isPicked = false
                this.$emit('unpick-img', img)
            } else {
                img.isPicked = true
                this.$emit('pick-img', img)
            }
        }
    }
}
</script>

<style scoped>

/* Create four equal columns that sits next to each other */
.column {
    padding: 0px 5px;
}

.column img {
  vertical-align: middle;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}

.white-background {
    background-color: white;
}

.img-hover-effect {
    cursor: pointer;
}

</style>