<template>
    <div>
        <a v-if="comments.hasNext" href="" @click.prevent="getMoreComment">Tải bình luận cũ hơn</a>
        <comment-block class="mt-4" :data="comment" v-for=" (comment,i) in comments.itemsInPage" :key="i" @hasnewreply="hasnewreply"></comment-block>
    </div>
</template>

<script>
import CommentBlock from '@/components/views/schedule-detail/CommentBlock.vue'
import {CommentService} from '@/services'

export default {
    components:{
        'comment-block': CommentBlock,
    },
    props:{
        targetId: {
            type: String, 
            required: true
        },
        comments:{
            type: Object,
            default: ()=>({
                hasNext: false,
                itemsInPage: [],
                count: 0
            })
        },
        
    },
    data() {
        return {
            currentPage:1
        }
    },
    methods: {
        getMoreComment() {
            CommentService.loadMoreComment(this.targetId, this.currentPage+1).then(res=>{

                this.comments.itemsInPage= res.itemsInPage.concat(this.comments.itemsInPage)
                this.currentPage+=1;
                this.comments.hasNext = res.hasNext
                this.comments.count = res.count
            })
        },
        hasnewreply(){
            this.$emit("hasnewreply")
        }
    },
}
</script>

<style scoped>
</style>