<template>
  <div class="bottom-spacing">
    <v-row>
      <v-col class="content" cols="7">
        <v-sheet class="pa-2">
        <div class="border-bottom">
          <h2>{{ title }}</h2>
        </div>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="3" class="hide-overflow slide-animation" :style="{height: imageContainerHeight}">
              <img src="@/assets/route.svg" alt="cung đường">
            </v-col>
            <v-col cols="9">
              <div
                v-for="(options, name) in fields"
                :key="name"
              >
                <div v-if="options.datepicker">
                  <v-menu
                    v-model="options.showPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="options.formattedValue"
                        v-on="on"
                        append-icon="mdi-calendar"
                        @blur="options.value = parseDate(options.formattedValue)"
                        :label="options.label"
                        :name="name"
                        :rules="options.required ? [rules.required] : []"
                        :error-messages="datePickerErrors"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="options.value"
                      no-title
                      locale="vi"
                      @input="options.showPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div v-else-if="options.textarea">
                  <v-textarea
                    v-model="options.value"
                    :label="options.label"
                    :name="name"
                    :rules="options.required ? [rules.required] : []"
                  ></v-textarea>
                </div>
                <div v-else-if="options.select">
                  <v-select
                    v-model="options.value"
                    :label="options.label"
                    :items="options.items"
                    item-text="text"
                    item-value="value"
                    :name="name"
                    :rules="options.required ? [rules.required] : []"
                  ></v-select>
                </div>
                <div v-else-if="options.money">
                  <v-text-field
                    v-model="options.value"
                    :label="options.label"
                    :id="'money_' + name"
                    v-currency="currencyOptions"
                    :name="name"
                    :rules="options.required ? [rules.required] : []"
                  ></v-text-field>
                </div>
                <div v-else-if="options.file">
                  <v-file-input
                    @change="addUploadPhotos"
                    @click:clear="clearUploadPhotos"
                    :placeholder="options.label"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                    append-icon="mdi-camera"
                    prepend-icon=""
                    multiple
                    counter
                    :counter-string="`${options.value.length}/${options.max_length} ảnh`"
                    :name="name"
                    :rules="options.required ? [rules.required, validateMaxLength(options.max_length)] : [validateMaxLength(options.max_length)]"
                  >
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0" class="text-muted">{{ options.label }}</span>
                    </template>
                    <template v-slot:append>
                      <router-link
                        :to="{ name: 'schedulePhotoGallery',
                               params: { username: loggedInUser.username,
                                         scheduleId: scheduleId },
                               query: { showBackBtn: true,
                                        enablePickingPhoto: true,
                                        pickedImgIds: pickedImgIds } }"
                        replace
                      >
                        <v-btn
                          depressed
                          small
                          @click="showDialog = true"
                        >Chọn từ album</v-btn>
                      </router-link>
                    </template>
                  </v-file-input>
                  <v-dialog
                    v-model="showDialog"
                    width="70vw"
                    content-class="bg-white px-3 pb-3 position-relative"
                  >
                    <div>
                      <router-view
                        class="fix-height"
                        @pick-img="pickImg($event)"
                        @unpick-img="unpickImg($event)"
                      ></router-view>
                      <v-btn
                        class="dialog-confirm"
                        dark
                        color="grey"
                        @click="showDialog = !showDialog"
                      >Xác nhận</v-btn>
                    </div>
                  </v-dialog>
                </div>
                <div v-else>
                  <v-text-field
                    v-model="options.value"
                    :label="options.label"
                    :type="options.type || 'text'"
                    :min="options.min"
                    :max="options.max"
                    :name="name"
                    :rules="options.required ? [rules.required] : []"
                  ></v-text-field>
                </div>
              </div>
              <div>
                <v-row>
                  <v-col
                    v-for="(photo, index) in fields.schedule_photos.value"
                    :key="index"
                    class="d-flex child-flex"
                    cols="3"
                  >
                    <img-view
                      :src="photo.src" :id="photo.id?photo.id: index"
                      @removed="removePhoto(index)">
                    </img-view>
                  </v-col>
                </v-row>
              </div>
              <div class="my-5">
                <v-btn depressed block rounded @click.prevent="submit">{{ btnTitle }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-row>
        <v-sheet class="pa-2">
        <div class="border-bottom">
          <h2>Lộ trình</h2>
        </div>
        <v-form ref="placeForm">
          <v-row
            v-for="(place, index) in places"
            :key="place.sequence.value"
          >
            <v-col cols="2" class="icon-container">
              <v-icon class="icon">mdi-map-marker</v-icon>
            </v-col>
            <v-col
              cols="10"
              @mouseover="place.showCloseIcon = true"
              @mouseleave="place.showCloseIcon = false"
            >
              <v-text-field
                v-model="place.place_name.value"
                :name="place.place_name.name"
                label="Tên địa điểm"
                dense
                :rules="[rules.maxLength(150)]"
              >
                <template slot="append">
                  <v-icon
                    v-show="place.showCloseIcon"
                    @click="removePlace(index)"
                  >mdi-close</v-icon>
                </template>
              </v-text-field>
              <v-combobox
                v-model="place.place_type.value"
                :items="place.place_type.items"
                label="Hoạt động"
                auto-select-first
                dense
                :rules="[rules.maxLength(50)]"
              ></v-combobox>
              <v-row>
                <v-col class="py-0">
                  <v-select
                    v-model="place.arrive_day.value"
                    :items="dayChoices"
                    item-text="text"
                    item-value="value"
                    label="Ngày"
                    dense
                  ></v-select>
                </v-col>
                <v-col class="py-0">
                  <v-menu
                    v-model="place.arrive_time.showPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="place.arrive_time.value"
                        label="Giờ"
                        append-icon="mdi-calendar-clock"
                        readonly
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="place.arrive_time.value"
                      header-color="grey"
                      scrollable
                      @change="place.arrive_time.showPicker = false"
                      dense
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="2" class="icon-container">
            <v-icon class="icon icon-pointer" @click="addPlace">mdi-plus-circle-outline</v-icon>
          </v-col>
          <v-col cols="10">
            <span class="text-muted" @click="addPlace">Thêm địa điểm...</span>
          </v-col>
        </v-row>
        </v-sheet>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {CurrencyDirective, parseCurrency, setValue} from 'vue-currency-input'

import _ from 'lodash'
import moment from 'moment'

import {ScheduleService} from '@/services'
import {PhotoService} from '@/services'
import ImgView from '@/components/editor/ImgOverlay.vue'
import {FileUtils} from '@/helpers'
import {authComputed} from '@/helpers'

Vue.use(_)
Vue.use(moment)

function initialData () {
  return {
    title: 'Tạo chuyến đi mới',
    btnTitle: 'Tạo chuyến đi',
    loading: false,
    valid: true,
    showDialog: false,
    currencyOptions: {
      currency: {
        prefix: '',
        suffix: '₫'
      },
      locale: 'en-US',  // There is no 'vi' locale, but 'en-US' use comma separator same as Viet Nam
      precision: 0,
      distractionFree: false,
      valueAsInteger: true,
      allowNegative: false
    },
    fields: {
      start_point: {
        value: '',
        label: 'Điểm xuất phát *',
        required: true
      },
      end_point: {
        value: '',
        label: 'Đích đến *',
        required: true
      },
      difficult_level: {
        value: 2,
        label: 'Độ khó *',
        select: true,
        items: [
          {text: 'Đường dễ đi', value: 1},
          {text: 'Chạy nhẹ nhàng', value: 2},
          {text: 'Đường khó đi', value: 3},
          {text: 'Cung đường thử thách', value: 4}
        ],
        required: true
      },
      vehicle_type: {
        value: 'MOTOR',
        label: 'Phương tiện',
        select: true,
        items: [
          {text: 'Xe máy', value: 'MOTOR'},
          {text: 'Ô tô', value: 'CAR'},
          {text: 'Xe đạp', value: 'BIKE'}
        ]
      },
      plan_start_date: {
        value: '',
        formattedValue: '',
        label: 'Ngày khởi hành *',
        datepicker: true,
        showPicker: false,
        required: true
      },
      plan_end_date: {
        value: '',
        formattedValue: '',
        label: 'Ngày kết thúc *',
        datepicker: true,
        showPicker: false,
        required: true
      },
      target_driver_number: {
        value: null,
        type: 'number',
        label: 'Số lượng xế?',
        min: 0
      },
      target_follower_number: {
        value: null,
        type: 'number',
        label: 'Số lượng ôm?',
        min: 0
      },
      fee: {
        value: '',
        label: 'Kinh phí dự kiến',
        money: true
      },
      description: {
        value: '',
        label: 'Mô tả',
        textarea: true
      },
      schedule_photos: {
        value: [],
        label: 'Ảnh đại diện chuyến đi',
        file: true,
        max_length: 10
      }
    },
    place: {
      sequence: {
        value: ''
      },
      place_name: {
        value: ''
      },
      place_type: {
        items: [
          'Check in',
          'Đổ xăng',
          'Ăn uống',
          'Nghỉ ngơi',
          'Khác: ',
        ],
        value: '',
      },
      arrive_day: {
        value: null
      },
      arrive_time: {
        value: null,
        showPicker: false
      },
      showCloseIcon: false
    },
    places: [],
    rules: {
      required: (input) => (input !== '' && input !== null && input !== undefined) ||
                            'Thông tin bắt buộc',
      maxLength(max) {
        return input => !input || (input.length <= max) ||
                        `Tối đa ${max} ký tự`
      }
    }
  }
}

export default {
  props: {
    scheduleId: {
      type: String,
      default: null
    },
  },
  directives: {
    currency: CurrencyDirective
  },
  components: {
    'img-view': ImgView,
  },
  data: function() {
    return initialData()
  },
  created: function() {
    this.addPlace()
  },
  mounted(){
    if(this.scheduleId){
      this.loadScheduleInfo()
    }
  },
  watch: {
    'fields.plan_start_date.value' () {
      if (this.fields.plan_start_date.value) {
        this.fields.plan_start_date.formattedValue = this.formatDate(this.fields.plan_start_date.value)

        if (this.fields.plan_start_date.value === this.fields.plan_end_date.value) {
          for (let place of this.places) {
            place.arrive_day.value = 1
          }
        }
      }
    },
    'fields.plan_end_date.value' () {
      if (this.fields.plan_end_date.value) {
        this.fields.plan_end_date.formattedValue = this.formatDate(this.fields.plan_end_date.value)

        if (this.fields.plan_start_date.value === this.fields.plan_end_date.value) {
          for (let place of this.places) {
            place.arrive_day.value = 1
          }
        }
      }
    },
    $route (newVal) {
      // Always reset the forms when user navigate to '/schedule-create'
      if (newVal.fullPath === '/schedule-create') {
        Object.assign(this.$data, initialData())
        this.$refs.form.resetValidation()
        this.addPlace()
      }
    }
  },
  methods: {
    validateMaxLength () {
      let length = this.fields.schedule_photos.value.length
      return length === 0 || length <= this.fields.schedule_photos.max_length ||
             'Vượt quá độ dài tối đa'
    },
    validateMaxCharacters () {

    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      const dateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      return dateString
    },
    formatCurrency (value) {
      const formattedValue = value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '₫'
      return formattedValue
    },
    createPlace: function(sequence) {
      var newPlace = _.cloneDeep(this.place)
      newPlace.sequence.value = sequence

      if (this.places.length > 0) {
        var lastPlace = this.places[this.places.length - 1]
        newPlace.arrive_day.value = lastPlace.arrive_day.value
      }

      return newPlace
    },
    addPlace: function() {
      var lastPlace = this.places[this.places.length - 1]
      var newPlace
      if (lastPlace) {
        newPlace = this.createPlace(lastPlace.sequence.value + 1)
      } else {
        newPlace = this.createPlace(1)
      }
      this.places.push(newPlace)
    },
    removePlace: function(index) {
      this.places.splice(index, 1)
    },
    removePhoto: function(index) {
      this.fields.schedule_photos.value.splice(index, 1)
    },
    submit: async function() {
      if (this.loading) return

      if (this._validate()) {
        this.loading = true

        var photoIds = []
        var uploadedPhotos = []
        for (let options of Object.values(this.fields.schedule_photos.value)) {
          if (options.id === null) {
            uploadedPhotos.push(options.src)
          } else {
            photoIds.push(options.id)
          }
        }

        var photos = await PhotoService.uploadPhotos(uploadedPhotos)

        for (let photo of photos) {
          photoIds.push(photo.id)
        }

        var requestBody = this._buildRequestBody(photoIds)
        this._sendRequest(requestBody)
      }
    },
    _validate: function() {
      return this.$refs.form.validate() && this.$refs.placeForm.validate()
    },
    _sendRequest: function(requestBody) {
      var self = this

      if (!this.scheduleId) {
        ScheduleService.createSchedule(requestBody)
          .then(function(result) {
            var id = result.data.id
            var url = '/detail/' + id
            self.$router.push(url)
          })
          .catch(function(error) {
            console.log(error)
          })
          .finally(function() {
            this.loading = false
          })
      } else {
        ScheduleService.updateSchedule(requestBody, self.scheduleId)
          .then(function(result) {
            console.log(result);
            var id = self.scheduleId
            var url = '/detail/' + id
            self.$router.push(url)
          })
          .catch(function(error) {
            console.log(error)
          })
          .finally(function() {
            this.loading = false
          })
      }
    },
    _buildRequestBody: function(photos) {
      var requestBody = new Object

      for (let [name, options] of Object.entries(this.fields)) {
        if (name === 'fee') {
          requestBody[name] = parseCurrency(options.value, this.currencyOptions)
        } else if (name === 'schedule_photos') {
          requestBody[name] = photos
        } else if (name === 'showCloseIcon') {
          continue
        } else if (options.datepicker) {
          requestBody[name] = moment(options.value, 'YYYY-MM-DD').toDate().getTime()
        } else {
          if (typeof options.value === 'string') {
            options.value = options.value.trim()
          }
          requestBody[name] = options.value
        }
      }

      requestBody.places = this._handlePlaces()
      return requestBody
    },
    _handlePlaces: function() {
      var inputPlaces = new Array
      for (let place of this.places) {
        if (this._isEmptyPlace(place)) {
          continue
        }

        let inputPlace = new Object
        for (let [name, options] of Object.entries(place)) {
          if (name === 'arrive_day' || name === 'arrive_time') {
            inputPlace.target_time = this._getTargetTime(place)
          } else if (name === 'place_type') {
            let value = options.value
            if (value.startsWith('Khác: ')) {
              value = value.substring(6, value.length)  // Remove "Khác: " from user input
            }
            inputPlace[name] = value
          } else {
            inputPlace[name] = options.value
          }
        }
        inputPlaces.push(inputPlace)
      }
      return inputPlaces
    },
    _getTargetTime: function(place) {
      var day = place.arrive_day.value
      var time = place.arrive_time.value
      if (!day) {
        return null
      } else if (!time) {
        time = '00:00'
      }

      var startDate = this.fields.plan_start_date.value
      var targetTime = moment(startDate, 'YYYY-MM-DD').toDate()
      targetTime.setDate(targetTime.getDate() + (day - 1))
      var hour = time.split(':')[0]
      var minute = time.split(':')[1]
      targetTime.setHours(hour)
      targetTime.setMinutes(minute)
      return targetTime.getTime()
    },
    _isEmptyPlace(place) {
      return !place.place_name.value &&
             !place.arrive_day.value &&
             !place.arrive_time.value
    },
    loadScheduleInfo(){
      ScheduleService.getScheduleDetail(this.scheduleId).then(scheduleInfo => {
        console.log(scheduleInfo);

        this.title = 'Chuyến đi: ' + scheduleInfo.startPoint + ' ~ ' + scheduleInfo.endPoint
        this.btnTitle = 'Sửa'
        this.fields.start_point.value = scheduleInfo.startPoint
        this.fields.end_point.value = scheduleInfo.endPoint
        this.fields.difficult_level.value = scheduleInfo.challengeLevel
        this.fields.vehicle_type.value = scheduleInfo.vehicleType
        this.fields.plan_start_date.value = scheduleInfo.startDate.toISOString().substr(0, 10)
        this.fields.plan_end_date.value = scheduleInfo.endDate.toISOString().substr(0, 10)
        this.fields.target_driver_number.value = scheduleInfo.allowAttachment
        this.fields.target_follower_number.value = scheduleInfo.allowDriver

        const moneyEl = document.getElementById('money_fee')
        setValue(moneyEl, scheduleInfo.budget)

        this.fields.schedule_photos.value = scheduleInfo.images
        this.fields.description.value = scheduleInfo.content

        this.places = []
        for (let point of scheduleInfo.points) {
          let place = this.createPlace(point.order)
          place.place_name.value = point.name
          place.place_type.value = point.type
          place.arrive_day.value = this.getArriveDay(point.atDatetime, scheduleInfo.startDate)
          place.arrive_time.value = this.getArriveTime(point.atDatetime)

          this.places.push(place)
        }
      })
    },
    getArriveDay (arriveDate, startDate) {
      // Remove all time of the 2 dates
      var arriveDateCopy = moment(arriveDate, 'YYYY-MM-DD').toDate()
      var startDateCopy = moment(startDate, 'YYYY-MM-DD').toDate()
      arriveDateCopy.setHours(0)
      arriveDateCopy.setMinutes(0)
      startDateCopy.setHours(0)
      startDateCopy.setMinutes(0)

      var delta = arriveDateCopy - startDateCopy
      delta = Math.floor(delta / 1000 / 60 / 60 / 24) + 1  // Get number of days
      return delta
    },
    getArriveTime (arriveDate) {
      var hour = arriveDate.getHours()
      var minute = arriveDate.getMinutes()
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    },
    addUploadPhotos (uploadedPhotos) {
      var self = this;
      uploadedPhotos.forEach(photo => {

        var reader = new FileReader
        reader.onload = function(event) {
          self.fields.schedule_photos.value.push({
            id: null,
            src: event.target.result,
            thumb: event.target.result
          })
        }
        //compress file
        FileUtils.compress(photo).then(compressedFile=>{
              reader.readAsDataURL(compressedFile)
        })
      })
    },
    clearUploadPhotos () {
      while (this.fields.schedule_photos.value.length > 0) {
        this.fields.schedule_photos.value.pop()
      }
    },
    pickImg (img) {
      this.fields.schedule_photos.value.push(img)
    },
    unpickImg (img) {
      let images = []
      for (let image of this.fields.schedule_photos.value) {
        images.push(image.id)
      }
      let index = images.indexOf(img.id)
      if (index > -1) {
        this.fields.schedule_photos.value.splice(index, 1)
      }
    }
  },
  computed: {
    ...authComputed,
    tripDuration: function() {
      var startDate
      var endDate
      for (let [name, options] of Object.entries(this.fields)) {
        if (name === 'plan_start_date') {
          startDate = moment(options.value, 'YYYY-MM-DD').toDate()
        }
        if (name === 'plan_end_date') {
          endDate = moment(options.value, 'YYYY-MM-DD').toDate()
        }
      }

      if (startDate && endDate && endDate > startDate) {
        var duration = endDate - startDate
        duration = Math.floor(duration / 1000 / 60 / 60 / 24) + 1  // Get number of days
        return duration
      }
      return 1
    },
    dayChoices: function() {
      var choices = new Array
      for (let i = 1; i <= this.tripDuration; i++) {
        choices.push({
          text: 'Ngày ' + i,
          value: i
        })
      }
      return choices
    },
    imageContainerHeight: function() {
      for (let [name, options] of Object.entries(this.fields)) {
        if (options.value === null ||
            options.value === '' ||
            JSON.stringify(options.value) === JSON.stringify([])) {
          let selector = '[name="' + name + '"]'
          let element = document.querySelector(selector)
          if (element) {
            let parent = element.parentElement
            let distanceFromTop = 0
            while (parent.offsetParent) {
              distanceFromTop += parent.offsetTop
              parent = parent.offsetParent
            }

            // Minute some pixels so that the route align with the input
            distanceFromTop -= 110
            return distanceFromTop + 'px'
          } else {
            return '0px'
          }
        }
      }
      return '830px'  // Equal height of inputs on the right
    },
    datePickerErrors () {
      let startDate
      let endDate
      for (let [name, options] of Object.entries(this.fields)) {
        if (name === 'plan_start_date') {
          startDate = moment(options.value, 'YYYY-MM-DD').toDate()
        } else if (name === 'plan_end_date') {
          endDate = moment(options.value, 'YYYY-MM-DD').toDate()
        }
      }
      return startDate && endDate && startDate > endDate ?
             'Chuyến đi kết thúc khi chưa bắt đầu?' :
             ''
    },
    pickedImgIds () {
      let ids = []
      for (let img of this.fields.schedule_photos.value) {
        ids.push(img.id)
      }
      return ids
    }
  },
}
</script>

<style scoped>


.hide-overflow {
  overflow: hidden;
}

.icon-container {
  display: flex;
}

.icon {
  margin-bottom: 8px;  /* Same as default margin bottom of <p> */
}

.slide-animation {
  transition: height 0.5s ease-in-out;
}

.blur-opacity {
  opacity: 0.7;
}

img {  /* Override default global style */
  max-height: none;
  max-width: none;
}

.fix-height {
  height: 100vh;
  background-color: white;
}

.dialog-confirm {
  position: fixed;
  bottom: 6.5vh;
  right: 16.5vw;
}
</style>
