import NProgress from 'nprogress'

const state = {
    loadingCount: 0
}

const mutations  ={
    START_LOADING(state){
        state.loadingCount+=1
        if(state.loadingCount>0){
            NProgress.start()
        }
    },
    LOADING_DONE(state){
        state.loadingCount-=1
        if(state.loadingCount<=0){
            NProgress.done()
        }
    },
    RESET_COUNTER(state){
        state.loadingCount = 0
        NProgress.done()
    }
}

export const ProgessBar = {
    state,
    mutations,
    namespaced: true
}