<template>
    <v-list two-line flat class="mx-2">
      <v-list-item-group v-for="(user,index) in users" :key="index">
          <v-row>
            <v-col cols="1"  class="d-flex align-items-center">
               <v-tooltip bottom v-if="user.isDriver">
                  <template v-slot:activator="{ on }">
                     <v-img :src="require('@/assets/icon/single_driver.png')" v-on="on" max-width="40" max-height="40"></v-img>
                  </template>
                  <span>Xế cô đơn</span>
                </v-tooltip>
                <v-tooltip bottom v-if="user.isAttachment">
                  <template v-slot:activator="{ on }">
                    <v-icon medium color="black" v-on="on">mdi-hail</v-icon>
                  </template>
                  <span>Ôm lẻ bóng</span>
                </v-tooltip>
                <v-tooltip bottom v-if="user.isCouple">
                  <template v-slot:activator="{ on }">
                     <v-img :src="require('@/assets/icon/couple.png')"  v-on="on" max-width="40" max-height="40"></v-img>
                  </template>
                  <span>Có đôi có cặp</span>
                </v-tooltip>
             
            </v-col>
            <v-col>
              <user-box :user="user"></user-box>
            </v-col>
            <v-col cols="1" class="d-flex align-items-center" v-if="hasPermission">
              <v-btn icon @click="approve(user)">
                <v-icon>mdi-check-decagram</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1" class="d-flex align-items-center" v-if="hasPermission">
              <v-btn icon @click="reject(user)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          
      </v-list-item-group>
      </v-list>

</template>

<script>
import SmallUserBox from '@/components/SmallUserBox.vue'
import {UserService} from '@/services'

export default {
    props:{
      schedule_id: {
        type: String,
        default: ''
      },
      users:{
        type: Array,
        default: ()=>([])
      },
      hasPermission:{
        type: Boolean, 
        default: false
      }
    },
    components:{
      'user-box': SmallUserBox
    },
    methods:{
      approve(member){
        UserService.approveRequest(this.schedule_id,member.username).then(()=>{
          this.$emit("request-approved", member)
        })
      },
      reject(member){
        UserService.rejectRequest(this.schedule_id,member.username).then(()=>{
          this.$emit("request-rejected", member)
        })
      }
    }
  }
</script>

<style scoped>
div.col{
  padding:0px;
}
</style>