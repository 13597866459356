<template>
    <v-card outlined>
        <v-card-text>
        <v-row no-gutters>   
            <v-col  cols="2" sm="1" >
                <v-avatar size="40">
                    <v-img :src="loggedInUser.avatar.thumb_sm"></v-img>
                </v-avatar>
            </v-col>
            <v-col  cols="10" sm="11">
                <v-textarea rows="3"
                auto-grow  solo flat
                v-model="content"
                placeholder="Ngày hôm nay của bạn thế nào?" counter="500"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row class="image-preview" no-gutters>
            <v-col cols="3" v-for="(photo, index) in photos" :key="index" class="pa-1">
                <img-view :src="photo" :id="index" @removed="removeImage"></img-view>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="1">
                <v-btn class="mx-auto action" icon @click="selectFile">
                    <v-icon>mdi-image</v-icon>
                </v-btn>
                <div style="width: 0px;height: 0px;overflow: hidden;"> <!--display:none does not trigger click event in safari-->
                    <v-file-input ref="fileupload" multiple @change="fileSelected" ></v-file-input>
                </div>
            </v-col>
            <v-col>
                <!-- <v-btn  class="mx-auto action" icon>
                    <v-icon>mdi-map-marker</v-icon>
                </v-btn> -->
            </v-col>
            <v-col cols="2">
                <v-btn  rounded small color="primary" class="float-right" @click="upStatus" :disabled="!canSave">
                    Đăng
                </v-btn>
            </v-col>
        </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {authComputed, PlaceApi, FileUtils} from '@/helpers'
import ImgView from '@/components/editor/ImgOverlay.vue'
import {StatusService} from '@/services'

export default {
    data () {
      return {
        loading: false,
        items: [],
        search: null,
        select: null,

        photos: [],
        content: ''
      }
    },
    computed:{
        ...authComputed,
        canSave(){
            return (this.content.length>0&& this.content.length<=500) || this.photos.length>0
        }
    },
    components:{
        'img-view': ImgView
    },
    watch:{
        search(val){
            val && this.querySelections(val)
        },
       
    },
    methods: {
      querySelections (v) {
        
        if(this.loading){
            return;
        }
        this.loading = true
        PlaceApi.search(v).then(response=>{
            console.log(response);
            this.loading = false
            this.items = response
        }).catch(()=>{
            this.loading= false
        })
      },

    

      selectFile(){
          this.$refs.fileupload.$refs.input.click()
      },
      fileSelected(selectedImanges){
        var self = this;
        selectedImanges.forEach(image => {
            //convert to base 64
            var reader = new FileReader();
            reader.onload = function(e){
                self.photos.push(e.target.result);
            }

            //compress file
            FileUtils.compress(image).then(compressedFile=>{
                 reader.readAsDataURL(compressedFile)
            })
        });
      },
      removeImage(index){
          console.log(index);
          this.photos.splice(index,1)
      },
      upStatus(){
          if(!this.canSave) return
          StatusService.upStatus({content: this.content, photos: this.photos}).then((status)=>{
            //   alert("Thành công")
              this.$emit("saved",status)
              this.clearForm()

          })
      }, 
      clearForm(){
          this.photos = []
          this.content = ''
      }
    },
}
</script>

