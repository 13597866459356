<template>
    <v-container>
        <v-row>
                <v-col cols="12">
                    <h4 class="d-inline">Ảnh đại diện</h4>
                    <div class="d-inline float-right">
                        <v-btn v-if="!avatar.editmode" small depressed color="primary" 
                            @click="avatar.editmode=true">
                            Sửa
                        </v-btn>
                        <v-btn v-if="avatar.editmode&& avatar.newAvatar" small depressed color="success" @click="updateAvatar">
                            Lưu
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-content-center">
                    <v-avatar size="150" class="avatar">
                        <v-img :src="avatar.url" alt="John" ></v-img>
                    </v-avatar>
                </v-col>
                <v-col cols="12" v-if="avatar.editmode">
                    <v-file-input label="" outlined dense v-model="avatar.newAvatar" @change="avatarSelected"></v-file-input>
                </v-col>
        </v-row>
        <v-row >
                <v-col cols="12">
                    <h4 class="d-inline">Ảnh bìa</h4>
                    <div class="d-inline float-right">
                        <v-btn v-if="!coverpicture.editmode" small depressed  color="primary" 
                        @click="coverpicture.editmode=true">
                            Sửa
                        </v-btn>
                        <v-btn v-if="coverpicture.editmode && coverpicture.newPicture" small depressed  color="success" @click="updateCoverPicture">
                            Lưu
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-content-center">
                    <v-img height="200" :src="coverpicture.url" aspect-ratio="2" class="rounded"></v-img>
                </v-col>
                <v-col cols="12" v-if="coverpicture.editmode">
                    <v-file-input label="" outlined dense @change="coverpictureSelected" v-model="coverpicture.newPicture"></v-file-input>
                </v-col>
        </v-row>
        <v-row >
                <v-col cols="12">
                    <h4 class="d-inline">Bio</h4>
                    <div class="d-inline float-right">
                        <v-btn small depressed  color="primary" v-if="!bio.editmode" @click="bio.editmode=true">
                            Sửa
                        </v-btn>
                        <v-btn small depressed  color="success" v-if="bio.editmode&&bio.value&&bio.value.length<=100" @click="updateBio">
                            Lưu
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-content-center">
                    <v-text-field :disabled="!bio.editmode" v-model="bio.value" counter="100"></v-text-field>
                </v-col>
        </v-row>
        <v-row >
                <v-col cols="12">
                    <h4 class="d-inline">Thông tin cá nhân</h4>
                    <div class="d-inline float-right">
                        <v-btn small depressed  color="primary" v-if="!personalInfo.editmode" 
                        @click="personalInfo.editmode=true">
                            Sửa
                        </v-btn>
                        <v-btn small depressed  color="success" v-if="personalInfo.editmode" 
                        @click="updatePersonalInfo"
                        >
                            Lưu
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-content-center">
                   <v-form v-model="personalInfo.valid" ref="personalInfo">
                        <v-container>
                        <v-row>
                            <v-col
                            cols="6"
                            >
                            <v-text-field :disabled="!personalInfo.editmode"
                                v-model="personalInfo.firstname"
                                :rules="personalInfo.validations.firstname"
                                :counter="20"
                                label="Họ"
                                required
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="6"
                            >
                            <v-text-field :disabled="!personalInfo.editmode"
                                v-model="personalInfo.lastname"
                                :rules="personalInfo.validations.lastname"
                                :counter="20"
                                label="Tên"
                                required
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            >
                            <v-text-field :disabled="true"
                                v-model="personalInfo.email"
                                :rules="personalInfo.validations.emailRules"
                                label="E-mail"
                                required
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-menu v-model="personalInfo.showBirthdayPicker"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field :disabled="!personalInfo.editmode"
                                        v-model="personalInfo.birthday"
                                        label="Ngày tháng năm sinh"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker no-title v-model="personalInfo.birthday" @input="personalInfo.showBirthdayPicker = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-form>
                </v-col>
        </v-row>
        <v-row >
        </v-row>
    </v-container>

</template>

<script>
import {authComputed} from '@/helpers'
import {UserService} from '@/services'
import moment from 'moment'

export default {
data: () => ({
    avatar:{
        editmode: false,
        newAvatar: null,
        url: '',
    },
    coverpicture:{
        editmode: false,
        newPicture: null, 
        url : '',
    },
    bio:{
        editmode: false,
        value: ''
    },
    personalInfo:{
        showBirthdayPicker : false,
        editmode: false,
        valid: false,
        firstname: '',
        lastname: '',
        birthday: '',
        email: '',
        validations: {
            emailRules: [
                v => !!v || 'Email của bạn là gì?',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            firstname: [
                v => !!v || 'Họ của bạn là gì?',
                v => v.length <= 20 || 'Hiện chúng tôi chỉ hỗ trợ nhỏ hơn 20 ký tự',
            ],
            lastname: [
                v => !!v || 'Tên của bạn là gì?',
                v => v.length <= 20 || 'Hiện chúng tôi chỉ hỗ trợ nhỏ hơn 20 ký tự',
            ], 
        }
    },

    
}),
mounted(){
    this.avatar.url= this.loggedInUser.avatar
    this.coverpicture.url = this.loggedInUser.coverPicture
    this.bio.value = this.loggedInUser.bio
    
    this.personalInfo.firstname = this.loggedInUser.firstname
    this.personalInfo.lastname = this.loggedInUser.lastname

    var m = moment(this.loggedInUser.birthday)
    this.personalInfo.birthday = m.format("YYYY-MM-DD")

    this.personalInfo.email = this.loggedInUser.email

},
computed:{
        ...authComputed
},
methods:{
    updateAvatar(){
        UserService.updateAvatar(this.avatar.url).then((avatar)=>{
            this.avatar.editmode=false
            this.$store.commit("user/SET_LOGGEDIN_USER",{avatar})
        }, error=>{
            console.log(error);
        })
    },
    updateCoverPicture(){
        UserService.updateCoverPicture(this.coverpicture.url).then((coverPicture)=>{
            this.coverpicture.editmode=false
            this.$store.commit("user/SET_LOGGEDIN_USER",{coverPicture})
        }, error=>{
            console.log(error)
        })
    },
    updateBio(){
        UserService.updateBio(this.bio.value).then((bio)=>{
            this.bio.editmode=false
            this.$store.commit("user/SET_LOGGEDIN_USER",{bio})
        }, error=>{
            console.log(error)
        })
    },
    updatePersonalInfo(){
        this.$refs.personalInfo.validate()
        if(!this.personalInfo.valid){
            return;
        }

        UserService.updatePersonalInfo( this.personalInfo).then((newInfo)=>{
            this.personalInfo.editmode=false
            this.$store.commit("user/SET_LOGGEDIN_USER",newInfo)
        },error=>{
            console.log(error)
        })
    },
    avatarSelected(){
        var self = this;
        if(self.avatar.editmode && self.avatar.newAvatar){
            var reader = new FileReader();
            reader.onload = function(e){
                self.avatar.url = e.target.result;
            }
            reader.readAsDataURL(self.avatar.newAvatar)
        }
    },
    coverpictureSelected(){
        var self = this;
        if(self.coverpicture.editmode && self.coverpicture.newPicture){
            var reader = new FileReader();
            reader.onload = function(e){
                self.coverpicture.url = e.target.result;
            }
            reader.readAsDataURL(self.coverpicture.newPicture)
        }
    }
}
}


</script>

<style scoped>
.coverpicture{
    max-width: 80%;
    max-height: 200px;
}
</style>