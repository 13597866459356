<template>
    <v-row>
        <v-spacer></v-spacer>
        <v-col cols='6'>
            <v-text-field class="title" v-model="current.title"
                label="Tiêu đề bài viết"
                flat solo counter="150">
            </v-text-field>
            <div v-if="false!=false">
                <v-autocomplete
                    v-model="current.selectPlace"
                    :items="places"
                    :loading="isLoading"
                    :search-input.sync="placeKeyword"
                    placeholder="Tại"
                    append-icon="mdi-map-marker"
                    return-object>
                </v-autocomplete>
            </div>
            <v-sheet>
            <editor v-model="current.content"></editor>
            </v-sheet>
            <v-switch v-model="current.isPublic" label="Công khai bài viết"></v-switch>
            <div class="float-right mr-4">
                <v-btn  rounded :disabled="!canSave"
                        @click="save">
                    {{!current.isPublic?'Lưu nháp':'Đăng bài'}}
                </v-btn>
            </div>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>

</template>

<script>
    import Editor from '@/components/editor/Editor.vue'
    import {ReviewService} from '@/services'
    export default {
        props:{
            id: {
                type: String, 
                default: ()=>null
            }
        },
        data() {
            return {
                isLoading: false,
                placeKeyword: '',
                places: [],

                init: {
                    id: null,
                    isPublic: false,
                    content: '',
                    title: '',
                    selectPlace: false,
                },
                current: {
                    id: null,
                    isPublic: false,
                    content: '',
                    title: '',
                    selectPlace: false,
                }
            }
        },
        components:{
            editor: Editor
        },
        computed:{
            canSave: function(){
                return this.current.content.length >0 && 
                    this.current.title.length>0 && this.current.title.length<=150 &&
                    !this.isLoading;
            },
        },
        mounted(){
            if(this.id){
                setTimeout(() => {
                    let data = this.$route.params.data
                    Object.assign(this.init, data)
                    Object.assign(this.current, data)       
                }, 1000);
            }
            
        },
        methods: {
           save(){
               if(!this.isDirty()) return;
               if(this.current.id){
                   ReviewService.updateReview(this.current.id, this.current.title, this.current.content, this.current.isPublic).then(res=>{
                       Object.assign(this.init, res)
                       if(this.current.isPublic){
                           this.$router.push(`/review-detail/${this.current.id}`)
                       }else{
                           alert("Đã lưu thành công")
                       }
                   })
               }else{
                   ReviewService.createReview(this.current.title, this.current.content, this.current.isPublic).then(res=>{
                       alert("tao thanh cong")
                        Object.assign(this.init, res)
                        this.current.id = res.id
                        if(this.current.isPublic){
                           this.$router.push(`/review-detail/${this.current.id}`)
                        }else{
                            alert("Đã Lưu thành công")
                        }
                    })
               }

           },
           isDirty: function(){
                return this.current.content != this.init.content ||
                        this.current.title != this.init.title ||
                        this.current.isPublic != this.init.isPublic ||
                        this.current.selectPlace != this.init.selectPlace
            },
        },
        beforeRouteEnter (to, from, next) {
            let id = to.params.id
            if(!id) {next()}
            else{
                ReviewService.getReviewForEdit(id).then(data=>{
                    to.params.data = data
                    next()
                })
            }
        },
        beforeRouteUpdate (to, from, next) {
            let id = to.params.id
            if(!id) {next()}
            else{
                ReviewService.getReviewForEdit(id).then(data=>{
                    to.params.data = data
                    next()
                })
            }
        },
        beforeRouteLeave(to, from, next){
            if(this.isDirty()){
                let answer = confirm("Bạn đã thay đổi nội dung nhưng chưa lưu, bạn có chắc chăn muốn rời đi")
                if(answer==true){
                    next()
                }else{
                    return
                }
            }
            next()
        }
    }
</script>

<style scoped>
.content{
    min-height: 100%;
}
.title{
    font-size: 2.5em;
}
</style>