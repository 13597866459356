var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.members.length >0)?_c('div',[_c('div',[_vm._v("Số lượng xế:"+_vm._s(_vm.driverCount))]),_c('div',[_vm._v("Số lượng ôm:"+_vm._s(_vm.attachmentCount))])]):_vm._e(),_c('v-list',{attrs:{"two-line":"","flat":""}},_vm._l((_vm.members),function(member,index){return _c('v-list-item-group',{key:index},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"1"}},[(member.isDriver)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({attrs:{"src":require('@/assets/icon/single_driver.png'),"max-width":"40","max-height":"40"}},on))]}}],null,true)},[_c('span',[_vm._v("Xế cô đơn")])]):_vm._e(),(member.isAttachment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":"","color":"black"}},on),[_vm._v("mdi-hail")])]}}],null,true)},[_c('span',[_vm._v("Ôm lẻ bóng")])]):_vm._e(),(member.isCouple)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({attrs:{"src":require('@/assets/icon/couple.png'),"max-width":"40","max-height":"40"}},on))]}}],null,true)},[_c('span',[_vm._v("Có đôi có cặp")])]):_vm._e()],1),_c('v-col',[_c('user-box',{attrs:{"user":member}})],1),_c('v-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"1"}},[(member.isOwner)?_c('div',[(member.isDriver)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-stamper")])]}}],null,true)},[_c('span',[_vm._v("Người khởi xướng")])]):_vm._e()],1):[(_vm.hasPermission)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeMember(member)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]],2)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }