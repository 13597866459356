<template>
     <div class="d-flex justify-center align-items-center" :class="!loggedIn?'footer': 'hidden-footer'">
         <login-form class="mx-5" v-if="!loggedIn"></login-form>
         <register-form v-if="!loggedIn"></register-form>
    </div>   
</template>

<script>
import LoginForm from '@/components/Login'
import RegisterForm from '@/components/AccountRegister'
import {authComputed} from '@/helpers'


export default {
    components:{
        'login-form': LoginForm,
        'register-form': RegisterForm
    },
    computed:{
        ...authComputed
    }
}
</script>

<style scoped>
.footer{
    max-height: 100px;
    min-height: 100px;
    background-color:gray;
    position: fixed;
    bottom: 0px;
    min-width: 100vw;
    z-index: 100;
}
.hidden-footer{
    display: none;
}
</style>