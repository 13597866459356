<template>
  <div>
    <v-dialog v-model="openDialog" max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn dark v-on="on">Tạo tài khoản</v-btn>
      </template>
      <v-card>
          <v-card-title
            class="headline grey lighten-2 mb-5" primary-title>
            <span>{{ currentTitle }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-row align="center" justify="center">
              <v-col cols="6" class="border-right">
                <!-- <v-window v-model="step">
                  <v-window-item :value="1"> -->
                    <v-form v-model="valid">
                        <v-text-field label="Email" v-model="loginInfo.email" :rules="validations.email" autofocus></v-text-field>
                        <v-text-field label="Tên đăng nhập" v-model="loginInfo.username" :rules="validations.username" counter="20"></v-text-field>
                        <v-text-field label="Mật khẩu" type="password" v-model="loginInfo.password" :rules="validations.password" counter="100"></v-text-field>
                        <v-text-field label="Xác nhận mật khẩu" type="password" v-model="loginInfo.confirmPassword" :rules="validations.confirmPass"></v-text-field>
                    </v-form>
                  <!-- </v-window-item>

                  <v-window-item :value="2">
                    <v-card-text>
                        <editprofile></editprofile>
                    </v-card-text>
                  </v-window-item>

                </v-window> -->
              </v-col>
              <v-col cols="6">
                <v-card
                  elevation="2"
                  class="mb-4"
                  @click="registerWithFacebook"
                >
                  <v-card-text>
                    <v-icon>mdi-facebook</v-icon>
                    Đăng ký bằng Facebook
                  </v-card-text>
                </v-card>
                <v-card
                  elevation="2"
                  @click="registerWithGoogle"
                >
                  <v-card-text>
                    <v-icon>mdi-google</v-icon>
                    Đăng ký bằng Google
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="agreeWithPolicy" class="mt-0" :error-messages="policyErrorMessages">
                  <template v-slot:label>
                    <div>Tôi đã đọc và đồng ý với&nbsp;<a href="/privacy-policy" target="_blank" @click.stop>điều khoản sử dụng</a></div>
                  </template>
                </v-checkbox>
                <span class="red--text" v-if="isConfirmPassNotMatch">Mật khẩu của bạn không khớp nhau</span>
                <span class="red--text">{{error}}</span>
                <span class="green--text">{{successMsg}}</span>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="gray" depressed @click="register" :disabled="!canRegister" class="w-100">
              Đăng ký
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import EditProfile from '@/components/EditProfile'

export default {
  data: () => ({
      agreeWithPolicy: false,
      valid: false,
      step: 1,
      openDialog: false,
      loginInfo:{
        email:'',
        username:'',
        password:'',
        confirmPassword:''
      },
      personalInfo:{
        firstname:'',
        lastname:''
      },
      error :'',
      successMsg:'',
      validations: {
        email: [
              v => !!v || 'Email của bạn là gì?',
              v => /.+@.+\..+/.test(v) || 'E-mail chưa đúng định dạng',
            ],
        username: [
          v => !!v || 'Bạn muốn đặt tên đăng nhập là gì?',
          v => v.length > 3 || 'Hãy nhập đặt tên đăng nhập lớn hơn 3 ký tự',
          v => v.length <= 20 || 'Tên đăng nhập của bạn quá dài',
          v => !v.includes(" ") || 'Tên đăng nhập không được chứa khoảng trắng',
        ],
        password: [
          v => !!v || 'Bạn mong muốn để mật khẩu là gì?',
          v => v.length >=8 || 'Hãy mật khẩu lớn hơn bằng 8 ký tự',
          v => v.length <=100 || 'Mật khẩu của bạn quá dài để nhớ',
        ],
        confirmPass:[
          v => !!v || 'Hãy xác nhận mật khẩu bạn đã nhập',
        ]
      },
      policyErrorMessages: [],
    }),

    computed: {
      currentTitle () {
        switch (this.step) {
          case 1: return 'Tạo tài khoản với Mê xê dịch!'
          case 2: return 'Thông tin cá nhân'
          default: return 'Hoàn thành'
        }
      },
      nextBtnLabel(){
        if(this.step>=2){
          return "Đóng"
        }
        return "Tiếp"
      },
      isConfirmPassNotMatch(){
        return this.loginInfo.confirmPassword &&(this.loginInfo.password !== this.loginInfo.confirmPassword)
      },
      canRegister(){
        return this.valid && this.error.length==0 && !this.isConfirmPassNotMatch && this.agreeWithPolicy
      }

    },
    watch:{
      loginInfo: {
        deep: true,
        handler(){
          if(this.error.length!=0){
            this.error=''
          }
        }
      },
      agreeWithPolicy (newVal) {
        if (newVal === true) {
          this.policyErrorMessages = []
        }
      }
    },
    methods:{
      register(){
        if(this.step==1){
          if(this.hasError){
            return;
          }
          this.$store.dispatch('user/register',this.loginInfo).then(()=>{

            this.successMsg = "Chúc mừng bạn đã tạo tài khoản thành công"
            setTimeout(()=>{
              this.step++
            },1000)
          }).catch(error=>{
            let errors = error.response.data.user
            this.error = ''
            for(let key in errors){
              this.error += errors[key]
            }
          })
          return;
        }
        this.openDialog=false;
      },

      registerWithFacebook () {
        if (!this.agreeWithPolicy) {
          this.policyErrorMessages.push('Bạn phải đồng ý với điều khoản sử dụng')
          return
        }

        const clientId = process.env.VUE_APP_SNS_FACEBOOK_CLIENT_ID
        const redirectUri = process.env.VUE_APP_SNS_FACEBOOK_REDIRECT_URI
        const url = `http://graph.facebook.com/oauth/authorize?client_id=${clientId}&scope=email&redirect_uri=${redirectUri}&response_type=token`
        window.location.href = url
      },

      registerWithGoogle () {
        if (!this.agreeWithPolicy) {
          this.policyErrorMessages.push('Bạn phải đồng ý với điều khoản sử dụng')
          return
        }

        const clientId = process.env.VUE_APP_SNS_GOOGLE_CLIENT_ID
        const redirectUri = process.env.VUE_APP_SNS_GOOGLE_REDIRECT_URI
        const url = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&prompt=consent&response_type=code&client_id=${clientId}&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&access_type=offline`
        window.location.href = url
      },
    },
    components:{
      // editprofile: EditProfile,
    }
}
</script>

<style scoped>

</style>