<template>
    <v-row>
    <v-col lg="8" xl="6" class="content ">
        <div v-if="loggedIn" class="mb-4">
            <editor @saved="newStatusUpped"></editor>
            
        </div>
        <activity-list :activities="activities" @loadMore="loadMore"></activity-list>
    </v-col>
    <v-col class="sidebar" lg="4" xl="4">
      
    </v-col>
  </v-row>
</template>

<script>
    import {SystemService} from '@/services'
    import ActivityList from '@/components/ActivityList.vue'
    import SimpleEditor from '@/components/editor/SimpleEditor.vue'
    import {authComputed} from '@/helpers'

    export default {
        data() {
            return {
                page: 1, 
                isLoading: false,
                hasNext: true,
                activities: []
            }
        },
        components:{
            'activity-list': ActivityList,
            editor: SimpleEditor
        },
        computed:{
            ...authComputed
        },
        mounted(){
            this.loadActivities()
            
        },
        methods:{
            loadMore(){
                if (!this.isLoading && this.hasNext) {
                    this.loadActivities()
                }
            },
            loadActivities(){
                this.isLoading = true
                SystemService.getSystemActivities(this.page, this.username).then(res=>{
                    this.render(res.activities)
                    this.page+=1
                    this.hasNext = res.hasNext  
                    this.isLoading =false  
                })
            },
            render(activities){
                activities.forEach(item=>{
                    this.activities.push(item)
                })
            },
            newStatusUpped(status){
                this.activities.unshift(status)
            }

        },
        beforeRouteUpdate (to, from, next){
            console.log(to);
            console.log(from);
            console.log(next);
            
            
            
            alert(1)
        }
    }
</script>

<style lang="scss" scoped>

</style>