<template>
    <v-row>
        <v-col md="12" lg="12" xl="8">
            <v-sheet class="content">
            <v-tabs grow color="gray">
                <v-tab href="#created_schedule" >
                    Chuyến đi đã tạo
                </v-tab>
                <v-tab-item  value="created_schedule">
                    <created-list></created-list>
                </v-tab-item>

                <v-tab  href="#appling_schedule">
                    Chuyến đi đang quan tâm
                </v-tab>
                <v-tab-item value="appling_schedule" >
                    <following-list></following-list>
                </v-tab-item> 

                <!-- <v-tab  href="#joint_schedule">
                    Chuyến đi đã tham gia
                </v-tab>
                <v-tab-item value="joint_schedule">
                    <completed-list></completed-list>
                </v-tab-item>  -->
            </v-tabs>
            </v-sheet>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import CreatedScheduleList from '@/components/views/mylist/CreatedScheduleList.vue'
import FollowingScheduleList from '@/components/views/mylist/FollowingScheduleList.vue'
// import CompletedScheduleList from '@/components/views/mylist/CompletedScheduleList.vue'

export default {
    components: {
        'created-list': CreatedScheduleList,
        'following-list': FollowingScheduleList,
        // 'completed-list': CompletedScheduleList,
    },
}
</script>

<style scoped>
</style>