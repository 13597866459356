import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/fb-sdk'
import VueLazyLoad from 'vue-lazyload'

// import axios from 'axios'
import '@/filters'

import 'nprogress/nprogress.css'
import Default from '@/layouts/Default.vue'
import NoSidebar from '@/layouts/NoSidebar.vue'

Vue.component("default-layout", Default)
Vue.component("no-sidebar-layout", NoSidebar)

Vue.config.productionTip = false
Vue.use(VueLazyLoad)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreated(){
    
  },
  created(){
    //auto login when refresh page
    const userString = localStorage.getItem('credentials')
    if(userString){
      this.$store.dispatch("user/getUser")
    }

  },
}).$mount('#app')
