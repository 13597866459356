import axios from 'axios'
// import lo from 'lodash'
import {Converter} from '@/helpers'

export const ScheduleService = {
    createSchedule,
    updateSchedule,
    getSchedules,
    getScheduleDetail,
    deleteSchedule,
    closeSchedule,
    cancelSchedule,
    getMySchedules,
    getMyFollowingSchedules,
    getMyCompletedSchedules,
    getSearchedSchedules
}

const urls ={
    createSchedule: "schedules/",
    updateSchedule: "schedules/{{id}}/",
}

function createSchedule(data) {
    return axios.post(urls.createSchedule, data)
}

function updateSchedule(data, id) {
    const url = urls.updateSchedule.replace('{{id}}', id.toString())
    return axios.put(url, data)
}

function getSchedules(page){
    return axios.get(`schedules/?page=${page}`).then(res=>{
        return _convertScheduleList(res)
    })
}
function _convertScheduleList(res){
    let schedules = []
    const data = res.data.results;
    for(const item of data){
        let schedule = Converter.convertOverallScheduleInfo(item)
        schedules.push(schedule)
    }
    return {
        schedules, 
        hasNext: res.data.next,
        total: res.data.count, 
        page: res.data.previous?res.data.page-1:1
    }
}


function getScheduleDetail(id){
    return axios.get(`schedules/${id}/`).then(response=>{
        let item = response.data

        let points = item.places.map(place=>_convertPlace(place))
        let requestingUsers = item.request_list.map(user=>_convertNestedUserInfo(user, "requester"))
        let members = item.members.map(member=>_convertNestedUserInfo(member, "member"))
        let comments =Converter.convertCommentPage(item.comments)

        return {
            id: item.id,
            createdDate: item.create_date,
            startDate: new Date(item.plan_start_date),
            endDate: new Date(item.plan_end_date),
            content: item.description,
            startPoint: item.start_point,
            endPoint: item.end_point,
            name: item.name,
            images: _convertImages(item.schedule_photos),
            allowAttachment: item.target_follower_number,
            allowDriver: item.target_driver_number,
            budget: item.fee,
            challengeLevel: item.difficult_level,
            status: item.status,
            vehicleType: item.vehicle_type,
            like:item.total_likes,
            follow:item.total_follows,
            creator: Converter.convertUser(item.create_by),
            points: points,
            requestingUsers: requestingUsers,
            members: members,
            comments: comments,
            liked: item.is_liked ? 1: -1,
            followed: item.is_followed ? 1: -1
        }
        
    })
}


function _convertPlace(input){
    return {
        id: input.id,
        atDatetime: new Date(input.target_time),
        name: input.place_name,
        type: input.place_type,
        order: input.sequence,
        lat:input.lat,
        lng: input.lng
    }
}

function _convertNestedUserInfo(input, nestedPropertyName){
    let perInfo = Converter.convertUser(input[nestedPropertyName])
    return Object.assign(perInfo,{
        isDriver: input.member_type==="DRIVER",
        isAttachment: input.member_type==="FOLLOWER",
        isCouple: input.member_type==="COUPLE",
        isOwner: input.is_creator,
    })
}




function _convertImages(images) {
    return images.map(img=>Converter.convertImage(img))
}

function deleteSchedule(schedule_id){
    return axios.delete(`schedules/${schedule_id}/`)
}

function closeSchedule(schedule_id){
    return axios.put(`schedules/${schedule_id}/update_status/`,{status: "STOP HIRING"})
}

function cancelSchedule(schedule_id) {
    return axios.put(`schedules/${schedule_id}/update_status/`,{status: "CANCEL"}) 
}

function getMySchedules(page) {
    return axios.get(`my_schedule/?page=${page}`).then(res=>{
        return _convertScheduleList(res)
    })
}

function getMyFollowingSchedules(page) {
    return axios.get(`my_following/?page=${page}`).then(res=>{
        return _convertScheduleList(res)
    })
}

function getMyCompletedSchedules(page) {
    return axios.get(`my_journey/?page=${page}`).then(res=>{
        return _convertScheduleList(res)
    })
}

function getSearchedSchedules(queryString, page) {
    return axios.get(`search/?${queryString}&page=${page}`).then(response=>{
        return _convertScheduleList(response)
    })
}
