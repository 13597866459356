import axios from 'axios'
import {Converter} from '@/helpers'


export const SystemService = {
    getSystemActivities
}

function getSystemActivities(page){
    return axios.get("activities", {params: {page}}).then(res=>{
        let data = res.data
        return {
            hasNext: data.next==true,
            total: data.count, 
            page: data.page,
            activities: data.results.map(activity => {
                if(activity.act_type=="STATUS"){
                    return Converter.convertUserStatus(activity.data)
                }else if(activity.act_type=="SCHEDULE"){
                    return Converter.convertOverallScheduleInfo(activity.data)
                }else if(activity.act_type=="ARTICLE"){
                    return Converter.convertReview(activity.data)
                }
            })
        }
    })
}