import axios from 'axios'
import { Converter } from '../helpers/converters';


function createReview(title, content, isPublic){
    return axios.post("article/", {title, content, is_published: isPublic}).then(res=>{
        return Converter.convertReview(res.data)
    })
}

function updateReview(id, title, content, isPublic){
    return axios.put(`article/${id}/`, {title, content,is_published: isPublic}).then(res=>{
        return Converter.convertReview(res.data)
    })
}

function deleteReview(id){
    return axios.delete(`article/${id}/`)
}

function getReviewForEdit(id) {
    return axios.get(`my_article/${id}/`).then(res=>{
        return Converter.convertReview(res.data)
    })
}

function readReview(id){
    return axios.get(`article/${id}/`).then(res=>{
        return Converter.convertReview(res.data)
    })
}

export const ReviewService = {
    createReview, 
    updateReview,
    getReviewForEdit, 
    deleteReview,
    readReview
}