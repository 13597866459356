<template>
    <v-list-item>
            <router-link :to="'/userprofile/'+user.username+'/timeline'">
            <v-list-item-avatar>
                <v-img :src="user.avatar.thumb_sm" alt="Avatar" @error="onError"></v-img>
            </v-list-item-avatar>
            </router-link>

            <v-list-item-content>
                <v-list-item-title class="username"><router-link :to="'/userprofile/'+user.username+'/timeline'">{{user.firstname+' '+user.lastname}}</router-link></v-list-item-title>
                <v-list-item-subtitle>{{user.level}} <slot name="after-subtitle"></slot></v-list-item-subtitle>
            </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props:{
        user:{
            type: Object,
            required: true,
            default: function(){return {
                userId:null,
                firstname: '',
                lastname:'',
                avatar: {
                    thumb_sm:null
                },
                level: ''
            }}
        }
    },
    methods:{
        onError(){
            this.user.avatar = {thumb_sm: require('@/assets/default_avatar.jpg')}
        }
    }
}
</script>

<style scoped>

</style>