<template>
    <v-carousel hide-delimiters :show-arrows="images.length>1">
        <v-carousel-item
        v-for="(image,i) in images"
        :key="i"
        :src="image.src"
        ></v-carousel-item>
    </v-carousel>

</template>

<script>
export default {
    props:{
        images: Array
    }
}
</script>

<style scoped>

</style>