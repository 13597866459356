<template>
  <v-card class="mb-2" flat outlined>
    <div class="creator d-flex align-items-center">
      <div class="flex-grow-1"><user-box :user="creator">
          <template v-slot:after-subtitle>{{schedule.createdDate|formatCreatedDate}}</template>
        </user-box>
      </div>
      <div class="mr-2">
        <v-tooltip bottom v-if="schedule.vehicleType=='CAR'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-car</v-icon>
          </template>
          <span>Phương tiện: Ô tô</span>
        </v-tooltip>
        <v-tooltip bottom v-if="schedule.vehicleType=='MOTOR'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-motorbike</v-icon>
          </template>
          <span>Phương tiện: Xe máy</span>
        </v-tooltip>
        <v-tooltip bottom v-if="schedule.vehicleType=='BIKE'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-bike</v-icon>
          </template>
          <span>Phương tiện: Xe đạp</span>
        </v-tooltip>
      </div>
    </div>
     <div class="schedule-detail" @click="goDetail">
        <v-card-title>Tuyển thành viên {{schedule.startPoint}} ~ {{schedule.endPoint}}</v-card-title>
        <v-card-text>
          <span style="font-size: 1.25em">Khởi hành {{schedule.startDate |simpleDatetime}} về {{schedule.endDate|simpleDatetime}}</span>
          <div class="description">
            <span>{{schedule.content.substring(0,300)}}</span>
            <template v-if="schedule.content.length >300">
              <span v-if="!seeMoreContent">...</span>
              <span class="remaining" v-if="seeMoreContent">{{schedule.content.substring(300)}}</span>
              <br>
              <a href="" v-on:click.stop.prevent="seeMoreContent=true" v-if="!seeMoreContent">Đọc thêm</a>
              <a href="" v-on:click.stop.prevent="seeMoreContent=false" v-if="seeMoreContent">Thu gọn</a>
            </template>
          </div>
        </v-card-text>
        
        <img-set :images="schedule.images"></img-set>

        <v-card-text>
          <v-chip-group column>
            <v-chip v-if="schedule.allowDriver>0" tag small>Tuyển xế({{schedule.allowDriver}})</v-chip>
            <v-chip v-if="schedule.allowAttachment>0" tag small>Tuyển ôm({{schedule.allowAttachment}})</v-chip>
            <v-chip v-if="schedule.budget>0" tag small>Chi phí({{schedule.budget|formatCurrency}})</v-chip>
            <v-chip v-if="schedule.challengeLevel==1" color="green" outlined small>Đường dễ đi</v-chip>
            <v-chip v-if="schedule.challengeLevel==2" color="primary" outlined small>Chạy nhẹ nhàng</v-chip>
            <v-chip v-if="schedule.challengeLevel==3" color="orange" outlined small>Đường khó đi</v-chip>
            <v-chip v-if="schedule.challengeLevel==4" color="red" outlined small>Cung đường thử thách</v-chip>
          </v-chip-group>
        </v-card-text>
    </div>
    <v-divider></v-divider>
    <v-card-actions>
      <div class="mx-auto action" v-bind:class="liked===1?'liked': ''">
        <v-btn
          :disabled="!loggedIn"
          icon
          v-bind:class="liked===1?'liked': ''"
          @click="like"
        >
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <span class="counter">{{schedule.like}}</span>
      </div>
      
      <div class="mx-auto action" >
        <v-btn :disabled="!loggedIn" icon @click="openCommentBox">
          <v-icon>mdi-comment</v-icon>
        </v-btn>
        <span class="counter">{{schedule.comment}}</span>
      </div>

      <div class="mx-auto action" v-bind:class="followed===1?'followed':''">
        <v-btn
          :disabled="!loggedIn"
          icon
          v-bind:class="followed===1?'followed':''"
          @click="follow"
        >
          <v-icon>mdi-bookmark</v-icon>
        </v-btn>
        <span class="counter">{{schedule.follow}}</span>
      </div>
    </v-card-actions>

    <comment-box v-model="quickcomment" @send="sendComment" :show="openQuickCommentBox" :height="3"></comment-box>

  </v-card>
</template>

<script>
import SmallUserBox from "@/components/SmallUserBox.vue";
import {CommentService, ReactionService} from '@/services'
import { authComputed } from "@/helpers";
import moment from 'moment'

import Commentbox from '@/components/CommentBox.vue'
import PreviewImgSet from '@/components/imageviewbox/PreviewImgSet.vue'

const MINUTE = 60
const HOUR = MINUTE*60
const DAY = HOUR*24

export default {
  props: {
    schedule: {
      type: Object,
      required: true,
      default: ()=>({
        id: null,
        createdDate: null,
        startDate: null,
        endDate: null,
        description: null,
        startPoint: null,
        name: null,
        images: [],
        allowAttachment: null,
        allowDriver: null,
        budget: 0,
        challengeLevel: null,
        comment: 0,
        like: 0,
        follow: 0
      })
    },
    creator: {
      type: Object,
      required: true,
      default: ()=>({
        username: null,
        firstname: "",
        lastname: "",
        avatar: "",
        level: ""
      })
    },
    liked: {
      //Value is 1 or -1
      type: Number,
      default: ()=>-1
    },
    followed:{
      //Value is 1 or -1
      type: Number,
      default: ()=>-1
    },
  },
  data: () => {
    return {
      openQuickCommentBox: false,
      quickcomment: "",
      seeMoreContent: false
    };
  },
  computed: {
    ...authComputed,
  },
  components: {
    "user-box": SmallUserBox,
    "comment-box": Commentbox,
    'img-set': PreviewImgSet

  },
  mounted(){
  },
  methods: {
    goDetail() {
      this.$router.push("/detail/"+this.schedule.id);
    },
    like() {
      this.liked = this.liked * -1;
      
      if(this.liked==-1){
        ReactionService.unlikeSchedule(this.schedule.id).then(()=>{
          
        })
      }else{
        ReactionService.likeSchedule(this.schedule.id).then(()=>{
        })
      }
      this.schedule.like += this.liked;
    },
    openCommentBox() {
      this.openQuickCommentBox = !this.openQuickCommentBox;
    },
    sendComment() {
      CommentService.commentForSchedule(this.schedule.id, this.quickcomment).then(()=>{
        this.openQuickCommentBox = false;
        this.schedule.comment+=1
      })
    },
    follow() {
      this.followed = this.followed * -1;
      
      if(this.followed==-1){
        ReactionService.unfollowSchedule(this.schedule.id).then(()=>{
        })
      }else{
        ReactionService.followSchedule(this.schedule.id).then(()=>{
        })
      }
      this.schedule.follow += this.followed;
    }
  },
  filters:{
    formatCreatedDate(date){
      let from = moment(new Date(date))
      let now = moment(new Date())
      
      let diffInSecond = now.diff(from, "seconds")

      let inDay = Math.floor(diffInSecond/DAY)
      if(inDay>=1){
          return new Date(date).toLocaleDateString('vi-VN')
      }
      let inHour = Math.floor(diffInSecond/HOUR)
      if(inHour>=1){
          return inHour+' giờ trước'
      }
      let inMinute = Math.floor(diffInSecond/MINUTE)
      if(inMinute>=1){
          return inMinute+' phút trước'
      }
      return 'Vừa xong'
    }
  }
};
</script>
<style scoped>
.action img {
  max-height: 25px;
  max-width: 25px;
  min-height: 25px;
  min-width: 25px;
}

.description{
  /* text-align: justify;
  text-justify: inter-word; */
  word-break: break-all;
  white-space: pre-wrap;
}

</style>
<style>
hr {
  margin-top: 0px;
  margin-bottom: 0px;
}
.counter{
    font-size: 0.875em;
    font-weight: 500
}

.liked,
.followed {
  color: orange !important;
}

.username{
    font-weight: bold;
    margin-right: 12px;
    text-decoration: none;
}
.schedule-detail:hover {
    cursor: pointer;
}
img{
  max-width: 100%;
  max-height: 100%;
}
</style>