import {Loader} from 'google-maps';

var google = null

async function search(keyword){
    if(!google){
        const options = {libraries:['places']};
        const loader = new Loader(process.env.VUE_APP_GOOGLE_API_KEY, options);
        
        google = await loader.load(options)
    }
    var request = {
        input: keyword,
    };

    var service = new google.maps.places.AutocompleteService(); 
    return new Promise((resolve, reject)=>{
        service.getPlacePredictions(request, function(results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                let formated = results.map(item=>{
                    return {main_text: item.structured_formatting.main_text,
                        secondary_text: item.structured_formatting.secondary_text,
                        place_id: item.place_id}
                })
                resolve(formated)
            }else{
                reject(results)
            }
        });
    })
}

export const PlaceApi = {
    search
}