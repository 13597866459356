import {UserService} from '@/services'
import axios from 'axios'

var BriefUserInfo = function(){
    this.username = ''
    this.id = ''

    this.firstname =''
    this.lastname = ''
    this.birthday = null
    this.level = ''
    this.avatar = {
        
    }
    this.coverPicture = ''
    this.joinedDate = null
    this.hasNewNoti = false
    this.bio = ''
}

const user = JSON.parse(localStorage.getItem('credentials'))
const state = user? {credentials:user,
                    tokenRefreshing: false,
                    loggedInUser : new BriefUserInfo()}
                    : {credentials: null,
                    tokenRefreshing: false,
                    loggedInUser: new BriefUserInfo()}

const mutations = {
    SET_CREDENTIALS(state, credentials){
        state.credentials = credentials
        localStorage.setItem('credentials', JSON.stringify(credentials));
        axios.defaults.headers.common["Authorization"] = `Bearer ${credentials.access}`
    },
    CLEAR_CREDENTIALS(){
        localStorage.removeItem('credentials')
        location.replace("/")
        delete axios.defaults.headers.common["Authorization"]
    },
    SET_LOGGEDIN_USER(state, user){
        state.loggedInUser= Object.assign({},state.loggedInUser,user)
    },
    CLEAR_LOGGEDIN_USER(state){
        state.loggedInUser = new BriefUserInfo()
    },
    START_REFRESH_TOKEN(state){
        state.tokenRefreshing = true
    },
    REFRESH_TOKEN_DONE(state){
        state.tokenRefreshing = false
    }   

}

const actions = {
    login({commit,dispatch}, {username, password}){
        return UserService.login(username, password).then(response=>{
            commit('SET_CREDENTIALS', response.data)
            return dispatch('getUser')
        })
    },
    refreshToken({state,commit}){
        return new Promise((resolve, reject)=>{
            if(state.tokenRefreshing){
                setTimeout(() => {
                    if(state.tokenRefreshing){
                        actions.refreshToken({state, commit})
                    }else{
                        resolve(state.credentials.access)
                    }
                    
                }, 1000);
            }else{
                commit("START_REFRESH_TOKEN")
                UserService.refreshToken(state.credentials.refresh).then(res=>{
                    commit('SET_CREDENTIALS', res.data)
                    commit("REFRESH_TOKEN_DONE")
                    resolve(res.data.access)
                }).catch(error=> reject(error))
            }
        })
        
        
    },
    logout({commit}){
        commit("CLEAR_CREDENTIALS")
    }, 
    register({commit,dispatch}, user){
        return UserService.register(user).then(response=>{
            commit('SET_CREDENTIALS', response.data.user)
            dispatch('getUser')
        })
    },
    authWithFacebook({ commit, dispatch }, accessToken) {
        return UserService.authWithFacebook(accessToken).then(response => {
            commit('SET_CREDENTIALS', response.data)
            dispatch('getUser')
        })
    },
    authWithGoogle({ commit, dispatch }, code) {
        return UserService.authWithGoogle(code).then(response => {
            commit('SET_CREDENTIALS', response.data)
            dispatch('getUser')
        })
    },
    getUser({commit}){
        return UserService.getCurrentLogin().then(userInfo=>{
            commit("SET_LOGGEDIN_USER", userInfo)
        })
    }
}

const getters ={
    loggedIn(state){
        return !!state.credentials
    },
    loggedInUser(state){
        let loggedInUser = Object.assign({},state.loggedInUser)
        return loggedInUser
    }
}

export const UserSession = { 
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}



