import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import ScheduleList from '../views/ScheduleList.vue'
import ScheduleDetail from '../views/ScheduleDetail.vue'
import MyList from '../views/MyList.vue'
import NotificationList from '../views/NotificationList.vue'
import ScheduleCreate from '../views/ScheduleCreate.vue'
import UserProfile from '../views/UserProfile.vue'
import UserTimeline from '../components/views/profile/UserTimeline.vue'
import PhotoGallery from '../components/views/profile/PhotoGallery.vue'
import AlbumDetail from '../components/views/profile/AlbumDetail.vue'
import ResetPassword from '../views/ResetPassword.vue'
import CreateReview from '../views/CreateReview.vue'
import ReviewDetail from '../views/ReviewDetail.vue'
import NotFound from '../views/NotFoundPage.vue'
import NetworkIssue from '../views/NetworkIssuePage.vue'
import AboutUs from '../views/About.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Index from '../views/Index.vue'
import StatusDetail from '@/views/StatusDetail.vue'
import SNSAuth from '@/views/SNSAuth.vue'

import store from '../store'
import axios from 'axios'


Vue.use(VueRouter)
Vue.use(VueMeta)

  const routes = [
  
  {
    path: '/detail/:id', 
    component: ScheduleDetail,
    props: true
  },
  {
    path: '/status/:id', 
    component: StatusDetail,
    props: true
  },
  {
    path: "/my-list",
    component: MyList,
    meta:{
      authRequired: true
    }
  },
  {
    path: "/notifications",
    component: NotificationList,
    meta:{
      authRequired: true
    }
  },
  {
    path: "/userprofile/:username/",//trick to fix https://stackoverflow.com/questions/56478628/vue-nested-routing-does-not-including-dynamic-parameters/61691898#61691898
    component: UserProfile,
    children:[
      {
        path: "timeline", 
        component: UserTimeline,
        props: true,
      },
      {
        path: "photos",
        component: PhotoGallery,
        props: true,
      },
      {
        path: "album/:albumId",
        component:AlbumDetail, 
        props: true
      },
    ]
  },
  {
    path: '/',
    name: 'index',
    component: Index
  },
  
  {
    path: '/find-schedule',
    name: 'ScheduleList',
    component: ScheduleList
  },
  {
    path: '/schedule-create/:scheduleId?',
    component: ScheduleCreate,
    props: true,
    meta:{
      authRequired: true
    },
    children: [
      {
        name: "schedulePhotoGallery",
        path: ":username/photos",
        component: PhotoGallery,
        props: true,
      },
      {
        name: "scheduleAlbumDetail",
        path: ':username/album/:albumId/',
        component: AlbumDetail,
        props: true,
      }
    ]
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/review/:id?',
    component: CreateReview,
    props: true,
    meta:{
      authRequired: true,
      layout: 'no-sidebar'
    }

  },
  {
    path: '/review-detail/:id',
    component: ReviewDetail,
    props: true,
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: NetworkIssue,
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs, 
    meta: {
      layout: 'no-sidebar',
      authRequired: true
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy, 
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/sns-auth/:provider',
    name: 'SNSAuth',
    component: SNSAuth,
    props: true,
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
      return { x: 0, y: 0 }
    }
  }
})



router.beforeEach((to,from, next)=>{
  //reset progress bar
  store.commit('progressBar/RESET_COUNTER')
  // prevent unauthenticated users from accessing restricted routes
  const loggedIn = localStorage.getItem('credentials')
  if(to.matched.some(record=> record.meta.authRequired) && !loggedIn){
    return next("/")
  }

  next();
})


axios.defaults.baseURL=process.env.VUE_APP_ROOT_API
axios.defaults.timeout=process.env.VUE_APP_REQUEST_TIMEOUT
axios.defaults.headers.post['Content-Type'] = 'application/json'

//set token into request header
const userString = localStorage.getItem('credentials')
if(userString){
  const userData = JSON.parse(userString)
  store.commit("user/SET_CREDENTIALS",userData)
}


axios.interceptors.response.use(
  response=>{
    store.commit("progressBar/LOADING_DONE")
    return response
  },
  error=>{
    store.commit("progressBar/LOADING_DONE")

    if(error.response.status===401){//first time call api to refresh token, if refresh token is invalid-> logout 
      if(error.config.url=='token/refresh'){
        store.dispatch('user/logout')
      }else{
        return store.dispatch("user/refreshToken").then((access)=> {
          const config = error.config
          config.headers["Authorization"] =`Bearer ${access}`
          return axios.request(config)
        })
      }
    }if(error.response.status === 404){
      router.push({name: 404})
    }if(error.response.status ===500){
      router.push({ name: 'network-issue' })
    }
    
    return Promise.reject(error)
  })

  
axios.interceptors.request.use(config=>{
  const credentials = localStorage.getItem('credentials')
  if(credentials){
    let token = JSON.parse(credentials)
    if(!store.state.user.credentials){ //last login user will override other 
      return config;
    }else if(token.access+ token.refresh != store.state.user.credentials.access+store.state.user.credentials.refresh){
      location.reload() //sync login info among multiple tab
    }
  }else if(store.state.user.credentials){//when user logout, clear credential of other tabs
    store.commit("user/CLEAR_CREDENTIALS")
    throw new axios.Cancel('Tải lại trang'); 
  }

  store.commit("progressBar/START_LOADING")
  return config
})

export default router
