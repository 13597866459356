<template>
        <v-row no-gutters>
            <v-col cols="9">
                <v-img :src="images[0].thumb_md" aspect-ratio="1">
                </v-img>
            </v-col>        
            <v-col cols="3">
                <v-row no-gutters>
                    <v-col 
                        v-for="(image,index) in images.slice(1,4)"
                        :key="index"
                        :cols="12"
                    >
                        <v-img :src="image.thumb_md" aspect-ratio="1">
                        </v-img>
                        <v-overlay absolute v-if="images.length>4&& index ==2">
                            <v-icon>+{{images.length-4}}</v-icon>
                        </v-overlay>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
</template>

<script>
    export default {
        props: {
            images: {
                type: Array,
                default: ()=>([])
            },
        },
    }
</script>

<style scoped>

</style>