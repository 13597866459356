<template>
  <v-container>
    <v-row v-show="!dialog">
      <v-col lg="8" xl="6" class="text-center">
        <p>
          Vui lòng đợi trong giây lát
        </p>
        <v-progress-circular
          indeterminate
          width="2"
          size="20"
        ></v-progress-circular>
        <p class="mt-5 link">
          <span
            @click="doAuth"
            class="clickable"
          >
            Click vào đây
          </span>
          nếu trang không tự động chuyển hướng
        </p>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="goHome"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2 mb-5"
          primary-title
        >
          Thông báo
        </v-card-title>
        <v-card-text>
          Tài khoản với email này đã tồn tại! <br>
          {{ helpText }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="mx-auto"
            depressed
            @click="goHome"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'SNSAuth',
  props: {
    provider: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    dialog: false,
    helpText: '',
  }),

  beforeMount () {
    this.doAuth()
  },

  methods: {
    doAuth () {
      if (this.provider === 'facebook') {
        this.authWithFacebook()
        this.helpText = 'Hãy thử đăng nhập lại bằng google'
      }
      else if (this.provider === 'google') {
        this.authWithGoogle()
        this.helpText = 'Hãy thử đăng nhập lại bằng facebook'
      }
    },

    authWithFacebook () {
      const hashAsQueryString = window.location.hash.slice(1)  // Remove '#' from to.hash so that it become a query string
      const urlParams = new URLSearchParams(hashAsQueryString)
      const accessToken = urlParams.get('access_token')

      this.$store.dispatch('user/authWithFacebook', accessToken)
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
            if (error.response.status === 409) {
              this.handleConflictEmail()
            }
        })
    },

    authWithGoogle () {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      const prompt = urlParams.get('prompt')

      if (prompt === 'consent') {
        this.$store.dispatch('user/authWithGoogle', code)
          .then(() => {
            this.$router.push('/')
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.handleConflictEmail()
            }
          })
      }
    },

    handleConflictEmail () {
      // Show warning dialog
      this.dialog = true
    },

    goHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.clickable {
  color: blue;
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.link {
  font-size: .9em;
}
</style>
