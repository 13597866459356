<template>
  <v-sheet
    class="pa-3 overflow-y-auto overflow-x-hidden"
    max-height="96vh"
  >
    <div class="border-bottom header">
      <h3 class="title">Tìm chuyến đi</h3>
    </div>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field autofocus
        v-model="fields.end_point"
        label="Điểm đến"
      ></v-text-field>
      <v-text-field
        v-model="fields.start_point"
        label="Điểm xuất phát"
      ></v-text-field>
      <multiple-select
        v-model="fields.region"
        :choices="choices.region"
        :isDense="true"
        @select-all="updateMulipleSelect($event, 'region')"
        label="Vùng miền"
      ></multiple-select>
      <multiple-select
        v-model="fields.vehicle"
        :choices="choices.vehicle"
        @select-all="updateMulipleSelect($event, 'vehicle')"
        label="Phương tiện"
      ></multiple-select>
      <v-row>
        <v-col class="py-0">
          <v-menu
            v-model="visible.start_date_after"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="start_date_after_formatted"
                v-on="on"
                label="Ngày đi"
                append-icon="mdi-calendar"
                @blur="fields.start_date_after = parseDate(start_date_after_formatted)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.start_date_after"
              no-title
              locale="vi"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="py-0">
          <v-menu
            v-model="visible.end_date_before"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="end_date_before_formatted"
                v-on="on"
                label="Ngày về"
                append-icon="mdi-calendar"
                @blur="fields.end_date_before = parseDate(end_date_before_formatted)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.end_date_before"
              no-title
              locale="vi"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <multiple-select
        v-model="fields.status"
        :choices="choices.status"
        @select-all="updateMulipleSelect($event, 'status')"
        label="Trạng thái"
      ></multiple-select>
      <v-select
        v-model="orders.fee"
        :items="choices.fee"
        :append-icon="feeOrderIcon"
        label="Sắp xếp chi phí"
        clearable
      ></v-select>
      <v-select
        v-model="orders.difficult"
        :items="choices.difficult"
        :append-icon="difficultOrderIcon"
        label="Sắp xếp độ khó"
        clearable
      ></v-select>
      <v-btn depressed block @click.prevent="submit">Tìm kiếm</v-btn>
    </v-form>
  </v-sheet>
</template>

<script>
import MultipleSelect from '@/components/form/MultipleSelect.vue'
import moment from 'moment'


export default {
  data () {
    return {
      valid: true,
      overflowY: false,
      fields: {
        start_point: '',
        end_point: '',
        region: [],
        vehicle: [],
        start_date_after: '',
        end_date_before: '',
        status: [],
      },
      start_date_after_formatted: '',
      end_date_before_formatted: '',
      orders: {
        fee: '',
        difficult: '',
      },
      choices: {
        region: [
          'Tây Bắc Bộ',
          'Đông Bắc Bộ',
          'Đồng Bằng Sông Hồng',
          'Bắc Trung Bộ',
          'Duyên Hải Nam Trung Bộ',
          'Tây Nguyên',
          'Đông Nam Bộ',
          'Tây Nam Bộ',
        ],
        vehicle: [
          {text: 'Xe máy', value: 'MOTOR'},
          {text: 'Ô tô', value: 'CAR'},
          {text: 'Xe đạp', value: 'BIKE'},
        ],
        status: [
          {text: 'Tuyển xế', value: 'DRIVER'},
          {text: 'Tuyển ôm', value: 'FOLLOWER'},
        ],
        fee: [
          {text: 'Thấp đến cao', value: 'fee'},
          {text: 'Cao đến thấp', value: '-fee'},
        ],
        difficult: [
          {text: 'Dễ đến khó', value: 'difficult_level'},
          {text: 'Khó đến dễ', value: '-difficult_level'},
        ],
      },
      visible: {
        start_date_after: false,
        end_date_before: false,
      }
    }
  },
  components: {
    'multiple-select': MultipleSelect
  },
  computed: {
    feeOrderIcon () {
      return this.getOrderIcon(this.orders.fee)
    },
    difficultOrderIcon () {
      return this.getOrderIcon(this.orders.difficult)
    }
  },
  watch: {
    'fields.start_date_after' () {
      this.start_date_after_formatted = this.formatDate(this.fields.start_date_after)
    },
    'fields.end_date_before' () {
      this.end_date_before_formatted = this.formatDate(this.fields.end_date_before)
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getOrderIcon (orderValue) {
      if (orderValue.length === 0) return 'mdi-sort'
      if (orderValue[0] !== '-') return 'mdi-sort-ascending'
      if (orderValue[0] === '-') return 'mdi-sort-descending'
    },
    updateMulipleSelect (selectedAllChoices, fieldName) {
      this.$nextTick(() => {
        if (selectedAllChoices) {
          this.fields[fieldName] = []
        } else {
          this.fields[fieldName] = []
          for (let option of this.choices[fieldName]) {
            let value = option.value || option
            this.fields[fieldName].push(value)
          }
        }
      })
    },
    submit () {
      var queryString = this.buildQueryString()
      this.$emit('schedule-response', queryString)
    },
    buildQueryString () {
      var query = new URLSearchParams

      for (let [key, value] of Object.entries(this.fields)) {
        if (!value) continue

        if (value instanceof Array) {
          for (let singleValue of value) {
            if (typeof singleValue === 'string') {
              singleValue = singleValue.trim()
            }
            query.append(key, singleValue)
          }
        }
        else if (['start_date_after', 'end_date_before'].includes(key)) {
          value = moment(value, 'YYYY-MM-DD').toDate()
          value = value.getTime()
          query.append(key, value)
        }
        else{
          if (typeof value === 'string') {
            value = value.trim()
          }
          query.append(key, value)
        }

      }

      for (let value of Object.values(this.orders)) {
        if(value){
          query.append('order_by', value)
        }
      }

      return query.toString()
    },
  },
}
</script>

<style scoped>

</style>
