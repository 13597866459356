<template>
    <v-row no-gutters>
        <v-col v-for="(activity, index) in activities" :key="index" cols="12">
            <status v-if="activity.type == 'status'" :status="activity" ></status>
            <schedule v-else-if="activity.type=='schedule'" :schedule="activity.schedule" :creator="activity.creator" :liked="activity.liked" 
                        :followed="activity.followed" >
            </schedule>
            <review v-else-if="activity.type=='review'" :review="activity"></review>
        </v-col>
    </v-row>
</template>

<script>
    import StatusCard from '@/components/StatusCard.vue'
    import ScheduleCard from '@/components/ScheduleCard.vue'
    import ReviewCard from '@/components/ReviewCard.vue'

    export default {
        props: {
           activities: {
               type: Array,
               default(){
                   return []
               }
           }
        },
        components:{
            status: StatusCard,
            schedule: ScheduleCard, 
            review: ReviewCard
        },
        mounted(){
            document.addEventListener('wheel', this.loadMore)
            document.addEventListener('scroll', this.loadMore)   
        },
        destroyed(){
            document.removeEventListener('wheel',this.loadMore)
            document.removeEventListener('scroll',this.loadMore)
        },
        methods:{
            loadMore(){
                
                if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                    this.$emit("loadMore")
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>