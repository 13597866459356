
import axios from 'axios'
import {Converter} from '@/helpers'

export const CommentService = {
    commentForSchedule,
    commentForStatus,
    commentForPhoto,
    commentForReview,
    replyComment, 
    loadMoreComment,
    loadMoreReplies,
}

function comment(target_type, target_id,content){
    return axios.post('/comment', {target_type,target_id,  content}).then(response=>{
        return Converter.convertComment(response.data)
    })
}

function commentForSchedule(targetId, content){
    return comment(TARGET_TYPE.SCHEDULE, targetId, content)
}
function commentForStatus(targetId, content){
    return comment(TARGET_TYPE.STATUS, targetId, content)
}

function commentForPhoto(targetId, content){
    return comment(TARGET_TYPE.PHOTO, targetId, content)
}

function commentForReview(targetId, content){
    return comment(TARGET_TYPE.REVIEW, targetId, content)
}

function replyComment(targetId, content){
    return comment(TARGET_TYPE.COMMENT, targetId, content)

}

function loadMoreComment(target_id, page){
    return axios.get('get_all_post_cmt', {params: {target_id, page}}).then(res=>{
        return Converter.convertCommentPage(res.data)
    })
}

function loadMoreReplies(reply_for, page){
    return axios.get('get_cmt_reply', {params: {reply_for, page}}).then(res=>{
        return Converter.convertCommentPage(res.data)
    })
}



const TARGET_TYPE={
    SCHEDULE: 1, 
    COMMENT:2,
    STATUS: 3, 
    PHOTO: 4,
    REVIEW: 5,
}

const REACTION = {
    LIKE: 1, 
    FOLLOW: 2
}

function reaction(target_id, target_type, action, method="post"){
    if(method=="delete"){
        return axios.delete("reaction", {data:{target_id, target_type, action}})
    }else{
        return axios.post("reaction", {target_id, target_type, action})
    }
}

function like(targetId, targetType){
    return reaction(targetId, targetType, REACTION.LIKE)
}

function unlike(targetId, targetType){    
    return reaction(targetId, targetType, REACTION.LIKE,"delete")
}

function likeComment(targetId){
    return like(targetId, TARGET_TYPE.COMMENT)
    
}

function unLikeComment(targetId){
    return unlike(targetId, TARGET_TYPE.COMMENT)
    
}

function likeSchedule(targetId){
    return like(targetId, TARGET_TYPE.SCHEDULE)
}

function unlikeSchedule(targetId){
    return unlike(targetId, TARGET_TYPE.SCHEDULE)
}

function likeStatus(targetId){
    return like(targetId, TARGET_TYPE.STATUS)
}

function unlikeStatus(targetId) {
    return unlike(targetId, TARGET_TYPE.STATUS)
}

function likePhoto(targetId) {
    return like(targetId, TARGET_TYPE.PHOTO)
}

function unlikePhoto(targetId) {
    return unlike(targetId, TARGET_TYPE.PHOTO)
}

function followSchedule(targetId) {
    return reaction(targetId, TARGET_TYPE.SCHEDULE, REACTION.FOLLOW)
}

function unfollowSchedule(targetId){
    return reaction(targetId, TARGET_TYPE.SCHEDULE, REACTION.FOLLOW, "delete")
}

function likeReview(targetId){
    return like(targetId, TARGET_TYPE.REVIEW)
}

function unlikeReview(targetId){
    return unlike(targetId, TARGET_TYPE.REVIEW)
}
export const ReactionService = {
    likeComment,
    unLikeComment,
    likeSchedule,
    unlikeSchedule,
    likeStatus, 
    unlikeStatus,
    likePhoto,
    unlikePhoto,
    followSchedule,
    unfollowSchedule,
    likeReview, 
    unlikeReview
}