import  Vue  from  "vue";
import {DateUtils} from '@/helpers'

Vue.filter("formatCurrency",function (val) {
    return new Intl.NumberFormat('vn-VN', { style: 'currency', currency: 'VND' }).format(val);
})

Vue.filter("capitalize",function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("calDuration",function(val){
    return DateUtils.durationUntilNow(val)
})

Vue.filter("localDatetime", function(val){
    //output: "Thứ Năm, 20 tháng 12, 2012"
    if(!val){
        return ''
    }
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return val.toLocaleDateString('vi-VN', options)
})
Vue.filter("simpleDatetime", function(val){
    if(!val){
        return ''
    }
    return val.toLocaleDateString('vi-VN')
})