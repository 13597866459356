<template>
    <div>
        <h5>Lịch trình chi tiết</h5>
        <v-timeline align-top dense>
            <v-timeline-item right
                small fill-dot color="primary"
                v-for="(point) in points" :key="point.id">
                <template v-slot:icon >
                    <v-icon color="white" v-if="point.type=='OTHER'">mdi-bike-fast</v-icon>
                    <v-icon color="white" v-if="point.type=='CHECK_IN'">mdi-map-marker</v-icon>
                    <v-icon color="white" v-if="point.type=='GAS_STATION'">mdi-fuel</v-icon>
                    <v-icon color="white" v-if="point.type=='REST_STATION'">mdi-bed</v-icon>
                    <v-icon color="white" v-if="point.type=='FOOD'">mdi-food</v-icon>
                </template>
                        <span class="font-weight-bold">{{point.atDatetime|formatDate}}:</span> {{point.name}}
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props:{
        points:{
            type: Array,
            required: true,
            default: ()=>([{
                    id: '',
                    atDatetime: null,
                    name: "",
                    type: "",
                    order: 0,
                    lat:null,
                    lng: null
            }])
        }
    },
    filters:{
        formatDate(val){
            if(!val){
                return ''
            }
            return moment(val).format("D/M HH:mm")
        }
    }

}
</script>

<style >
</style>