<template>
    <v-hover v-slot:default="{ hover }">
        <div style="position: relative">
            <v-img :src="src" alt="John" aspect-ratio="1" class="grey lighten-2">
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-fade-transition>
                <v-overlay
                    v-show="hover"
                    absolute
                    color="#036358"
                >
                    <v-btn icon @click="$emit('removed', id)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-overlay>
            </v-fade-transition>
        </div>
    </v-hover>
</template>

<script>
    export default {
       props:{
           src: {
               type: String, 
               required: true
           },
           id: {
               type: [String, Number],
               required: true
           }
       }
    }
    
</script>

<style scoped>

</style>