<template>
    <v-row no-gutters>
        <v-col cols="8">
            <v-row no-gutters>
                <v-col
                    v-for="(image, index) in images.slice(0,2)"
                    :key="index"
                    :cols="12"
                >
                    <v-img
                        :src="image.thumb_md"
                        :aspect-ratio="8/6"
                    >
                    </v-img>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="4">
            <v-row no-gutters>
                <v-col
                    v-for="(image, index) in images.slice(2,5)"
                    :key="index"
                    cols="12"
                >
                    <v-img
                        :src="image.thumb_md"
                        aspect-ratio="1"
                    >
                    </v-img>
                    <v-overlay absolute v-if="images.length>5&& index ==2">
                        <v-icon>+{{images.length-5}}</v-icon>
                    </v-overlay>
                </v-col>
            </v-row>
        </v-col>
        
    </v-row>
</template>

<script>
    export default {
        props: {
            images: {
                type: Array,
                default: ()=>([])
            },
        },
    }
</script>

<style scoped>

</style>