<template>
  <v-row>
    
    <v-col lg="8" xl="6" class="content">
        <v-sheet class="fill-height pa-2" v-if="schedules.length==0">
          <span v-if="!isLoading">
          Rất tiêc, Chúng tôi không tìm thấy kết quả nào
          </span>
        </v-sheet>
        <template v-else>
        <schedule-card :schedule="schedule.schedule" :creator="schedule.creator" :liked="schedule.liked" 
          :followed="schedule.followed" v-for="(schedule,index) in schedules" :key="index">
        </schedule-card>
        </template>
    </v-col>
    <v-col class="sidebar" md="4" xl="3">
      <v-row class="sticky">
        <schedule-search @schedule-response="filterSchedule"></schedule-search>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ScheduleCard from '@/components/ScheduleCard.vue'
import ScheduleSearch from '@/components/views/schedule-list/ScheduleSearch.vue'
import {authComputed} from '@/helpers'
import {ScheduleService} from '@/services'

export default {
  data: function(){
    return {
      title: "Home",
      schedules: [],
      page: 1,
      hasNext: false,
      isLoading: false,
      scheduleResponse: {},
      isFilteringMode: false
    }
  },
  mounted(){
    document.addEventListener('wheel', this.loadMore)
    document.addEventListener('scroll', this.loadMore)
    this.loadSchedule()
  },
  destroyed(){
    document.removeEventListener('wheel',this.loadMore)
    document.removeEventListener('scroll',this.loadMore)
  },
  computed: {
    ...authComputed
  },
  components: {
    "schedule-card": ScheduleCard,
    "schedule-search": ScheduleSearch,

  },
  methods:{
    loadMore(){
        if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.isLoading && this.hasNext) {
            this.isFilteringMode?this.filterSchedule():this.loadSchedule()
        }
    },
    loadSchedule(){
      this.switchModel(false)
      this.isLoading= true,
      ScheduleService.getSchedules(this.page).then(response=>{
        this.hasNext = response.hasNext
        this.page+=1
        this.renderSchedules(response.schedules)
      })
    },
    filterSchedule (queryString) {
      this.switchModel(true)
      this.isLoading= true,
      ScheduleService.getSearchedSchedules(queryString, this.page).then(response=>{
        this.hasNext = response.hasNext
        this.page+=1
        this.renderSchedules(response.schedules)
      })
    },
    renderSchedules (schedules) {
      this.schedules = []
      schedules.forEach(schedule => this.schedules.push(schedule))
      this.isLoading = false
    },
    switchModel(isFilteringMode){
      this.isFilteringMode = isFilteringMode
      this.page = 1
    }
  }
}
</script>

<style >
.header {
  height: 47px;
}

.header h3 {
  margin: 0;
  line-height: 47px !important;  /* Override vuetify's "title" class */
}

.content{
  min-height: 100%;
}
</style>
<style scoped>
.sticky {
  position: sticky;
  top: 10px;
}
</style>