<template>
    <div>
        <comment-item class="mt-4" :data="data" isTopLevel @newreply="newreply"></comment-item>
        <div v-if="data.replies.count>0">
            <a v-if="data.replies.hasNext" href="" style="margin-left: 50px;" @click.prevent="loadMoreReplies">Tải thêm</a>
            <div  v-for=" (comment ) in data.replies.itemsInPage" :key="comment.id" style="margin-left: 50px;">
                <comment-item class="mt-1" :data="comment" ></comment-item>
            </div>
        </div>
    </div>
</template>

<script>
import CommentItem from '@/components/views/schedule-detail/CommentItem.vue'
import {CommentService} from '@/services'

export default {
    components:{
        'comment-item': CommentItem,
    },
    props:{
        data: {
            type: Object,
            required: true,
            default:()=>({
                "id": 1,
                "user": {
                    "username": "",
                    "last_name": "",
                    "first_name": "",
                    "experience": null,
                    "avatar": {}
                },
                "create_date": null,
                "update_date": null,
                "content": "",
                "target_id": "",
                "replies": {
                    hasNext: false,
                    itemsInPage: [],
                    count: 0
                },
                liked: -1,
                like: 0
            })
        },
    },
    data() {
        return {
            currentPage: 1
        }
    },
    methods:{
        newreply(reply){
            this.data.replies.itemsInPage.push(reply)
            this.data.replies.count +=1
            this.$emit("hasnewreply")
        }, 
        loadMoreReplies(){
            CommentService.loadMoreReplies(this.data.id, this.currentPage+1).then(res=>{
                this.data.replies.itemsInPage= res.itemsInPage.concat(this.data.replies.itemsInPage)
                this.currentPage+=1;
                this.data.replies.hasNext = res.hasNext
                this.data.replies.count = res.count
            })
        }
    },
}
</script>

<style scoped>
</style>