import axios from 'axios'
import moment from 'moment'
import { Converter } from '../helpers/converters'

export const UserService = {
    login,
    register,
    authWithFacebook,
    authWithGoogle,
    refreshToken,
    getCurrentLogin,
    updateAvatar,
    updateBio,
    updateCoverPicture,
    updatePersonalInfo,
    getUserInfo,
    sendForgotPasswordRequest,
    resetPassword,
    changePassword,
    getUserTimeline,
    requestJoinAsDriver,
    requestJoinAsCouple,
    requestJoinAsAttachment,
    leaveSchedule,
    approveRequest,
    rejectRequest,
    removeMember,
}

const urls = {
    login: "login",
    register: "users",
    currentLoggedIn: "me",
    updateAvatar: "profile",
    getUserInfo: 'profile',
    forgotPass: 'user/reset-password',
    changePassword:'user/change-password',
    snsAuth: 'oauth/login',
}

function login(username, password) {
    return axios.post(urls.login, { username, password })
}

function register({ username, password, confirmPassword, email }) {
    let data = { username, password, confirmPassword, email }
    return axios.post(urls.register, { user: data })
}

function authWithFacebook (accessToken) {
    return axios.post(urls.snsAuth, {
        provider: 'facebook',
        access_token: accessToken,
    })
}

async function authWithGoogle (code) {
    const clientId = process.env.VUE_APP_SNS_GOOGLE_CLIENT_ID
    const clientSecret = process.env.VUE_APP_SNS_GOOGLE_CLIENT_SECRET
    const redirectUri = process.env.VUE_APP_SNS_GOOGLE_REDIRECT_URI
    const url = `https://oauth2.googleapis.com/token?code=${code}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUri}&grant_type=authorization_code`

    const response = await axios.post(url)    
    return axios.post(urls.snsAuth, {
        provider: 'google-oauth2',
        access_token: response.data.access_token
    })
}

function refreshToken(refresh){
    return axios.post("token/refresh",{refresh})
}

function getCurrentLogin() {
    return axios.get(urls.currentLoggedIn).then(response=>{
        let res = response.data.me
        return Converter.convertUser(res)
    })
}

function getUserInfo(username){
    return axios.get(urls.getUserInfo+"/"+username).then(response=>{
        let res = response.data.profile
        return Converter.convertUser(res)
    })
}


function updateAvatar(avatar) {
    return axios.put(urls.updateAvatar, {"profile":{avatar}}).then(response=>{
        return Converter.convertImage({photo:response.data.profile.avatar})
    })
}

function updateBio(bio){
    return axios.put(urls.updateAvatar,  {"profile":{bio}}).then(res=>{
        return res.data.profile.bio
    })
}

function updateCoverPicture(cover_image){
    return axios.put(urls.updateAvatar, {"profile":{cover_image}}).then(response=> Converter.convertImage({photo: response.data.profile.cover_image}))
}

function updatePersonalInfo(perInfo){
    let birthday = moment(perInfo.birthday, "YYYY-MM-DD").toDate().getTime()
    let data = {
        first_name: perInfo.firstname,
        last_name: perInfo.lastname,
        email: perInfo.email,
        dob: birthday
    }
    return axios.put(urls.updateAvatar, {"profile":data}).then(response=>{
        let data = response.data.profile
        return {
            firstname: data.first_name,
            lastname: data.last_name,
            email: data.email,
            birthday: new Date(data.dob)
        }
    })
}

function sendForgotPasswordRequest(email){
    return axios.post(urls.forgotPass, {email})
}

function resetPassword(token, password){
    return axios.post(urls.forgotPass+"/"+token, { password })
}

function changePassword(old_password, new_password){
    return axios.put(urls.changePassword, {"user":{old_password, new_password}})
}


function getUserTimeline(page, username){
    return axios.get(`activities/${username}`,{params: {page}}).then(res=>{
        let data = res.data
        return {
            hasNext: data.next==true,
            total: data.count,
            page: data.page,
            activities: data.results.map(activity => {
                if(activity.act_type=="STATUS"){
                    return Converter.convertUserStatus(activity.data)
                }else if(activity.act_type=="SCHEDULE"){
                    return Converter.convertOverallScheduleInfo(activity.data)
                }else if(activity.act_type=="ARTICLE"){
                    return Converter.convertReview(activity.data)
                }
            })
        }

    })
}


function requestJoinAsDriver(scheduel_id, message){
    return axios.post(`schedules/${scheduel_id}/join/`,{member_type:"DRIVER", message})
}


function requestJoinAsAttachment(scheduel_id,message){
    return axios.post(`schedules/${scheduel_id}/join/`,{member_type:"FOLLOWER", message})
}

function requestJoinAsCouple(scheduel_id,message){
    return axios.post(`schedules/${scheduel_id}/join/`,{member_type:"COUPLE", message})
}

function leaveSchedule(schedule_id){
    return axios.delete(`schedules/${schedule_id}/left/`)
}

function approveRequest(schedule_id, member){
    return axios.put(`schedules/${schedule_id}/requests/${member}`,{status: "ACCEPTED"}).then(response=>{
        return response.data
    })
}
function rejectRequest(schedule_id, member) {
    return axios.put(`schedules/${schedule_id}/requests/${member}`,{status: "REJECT"})

}

function removeMember(schedule_id,member) {
    return axios.delete(`schedules/${schedule_id}/members/${member}`)
}
