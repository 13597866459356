<template>
    <activity-list :activities="activities" @loadMore="loadMore"></activity-list>
</template>

<script>
    import {UserService} from '@/services'
    import ActivityList from '@/components/ActivityList.vue'

    export default {
        props: {
            username: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                page: 1, 
                isLoading: false,
                hasNext: true,
                activities: []
            }
        },
        components:{
            'activity-list': ActivityList
        },
        mounted(){
            this.loadUserActivities()
            
        },
        methods:{
            loadMore(){
                if (!this.isLoading && this.hasNext) {
                    this.loadUserActivities()
                }
            },
            loadUserActivities(){
                this.isLoading = true
                UserService.getUserTimeline(this.page, this.username).then(res=>{
                    this.render(res.activities)
                    this.page+=1
                    this.hasNext = res.hasNext  
                    this.isLoading =false  
                })
            },
            render(activities){
                activities.forEach(item=>{
                    this.activities.push(item)
                })
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>