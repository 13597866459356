<template>
    <div>
        Rất xin lỗi, Chúng tôi không tìm thấy trang
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>