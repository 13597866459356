<template>
<v-col>
    <div v-if="items.length==0">
        Chúng tôi không tìm thấy chuyến đi nào
    </div>
    <v-row v-else no-gutters>
        <v-col md="4" v-for="(item, index) in items" :key="index" class="pa-1">
            <v-card class="schedule-item" @click.native="moveDetail(item.schedule.id)" flat outlined>

                <v-img v-if="item.schedule.images.length >0"  class="white--text align-end img"
                    :src="item.schedule.images[Math.floor(Math.random()*(item.schedule.images.length-1))]">
                     <v-card-title>
                        {{item.schedule.startPoint}}~{{item.schedule.endPoint}}
                    </v-card-title>
                </v-img>
                <v-img v-else class="white--text align-end img" :src="require('@/assets/no-image.png')">
                     <v-card-title>
                        {{item.schedule.startPoint}}~{{item.schedule.endPoint}}
                    </v-card-title>
                </v-img>

                <v-card-subtitle>
                    {{item.schedule.startDate|simpleDatetime}}~{{item.schedule.endDate| simpleDatetime}}
                </v-card-subtitle>
                <v-card-actions>
                   <div v-bind:class="item.liked===1?'liked': ''">
                        <v-btn icon v-bind:class="item.liked===1?'liked': ''">
                            <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <span class="counter">{{item.schedule.like}}</span>
                    </div>
                    <div>
                        <v-btn icon>
                            <v-icon>mdi-comment</v-icon>
                        </v-btn>
                        <span class="counter">{{item.schedule.comment}}</span>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <div class="text-center">
    <v-pagination circle v-if="totalPage>=2"
      v-model="currentPage"
      :length="totalPage"
      :total-visible="5"
      @next="changePage"
      @previous="changePage"
      @input="changePage"
    ></v-pagination>
  </div>
</v-col>
</template>

<script>
    import {ScheduleService} from '@/services'
    import {authComputed} from '@/helpers'
    export default {
    data: () => ({
        totalItem: 0,
        currentPage: 1,
        items: [],
    }),
    mounted(){
        this.loadSchedule()
    },
   
    computed:{
        ...authComputed,
        header(){
            if(this.totalItem==0){
                return "Rất tiếc, bạn chưa có chuyến đi nào"
            }
            return `Bạn đã có tất cả ${this.totalItem} chuyến đi`
        },
        totalPage(){
            return  Math.ceil(this.totalItem/30)
        }
    },
    methods: {
        changePage() {
            this.loadSchedule()
        },
        loadSchedule(){
            ScheduleService.getMySchedules(this.currentPage).then(response=>{
                this.items = response.schedules
                this.totalItem = response.total
            })
        },
        moveDetail(id){
            this.$router.push(`/detail/${id}`)
        }
    },
  }
</script>

<style scoped>
.schedule-item:hover{
    
    cursor: pointer;
}
.schedule-item{
    min-width: 100%;
    max-height: 300px;
    display: inline-block;
}
.schedule-item .img{
    max-height: 170px;
    min-height: 170px;
}
</style>