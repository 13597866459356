<template>
        <v-row no-gutters>
            <v-col
              v-for="(image, index) in images.slice(0,4)"
              :key="index"
              cols="6"
            >
                <v-img
                  :src="image.thumb_md"
                  aspect-ratio="1"
                >
                </v-img>
                <v-overlay absolute v-if="images.length>4&& index ==3">
                    <v-icon>+{{images.length-4}}</v-icon>
                </v-overlay>
            </v-col>
        </v-row>
</template>

<script>
export default {
   props: {
       images: {
           type: Array,
           default: ()=>([])
       },
   },
}
</script>

<style scoped>

</style>