<template>
    <v-tabs grow color="gray">
        <v-tab href="#album" >
            Album<span v-if="albumCount>0">({{albumCount}})</span>
        </v-tab>
        <v-tab-item  value="album">
            <album :username="username" @loaded="albumsLoaded"></album>
        </v-tab-item>

        <v-tab  href="#all_photos">
            Ảnh của tôi<span v-if="photoCount>0">({{photoCount}})</span>
        </v-tab>
        <v-tab-item value="all_photos">
            <all-photo
                :username="username"
                :pickedImgIds="$route.query.pickedImgIds"
                @loaded="photosLoaded"
                @pick-img="$emit('pick-img', $event)"
                @unpick-img="$emit('unpick-img', $event)"
            ></all-photo>
        </v-tab-item>
    </v-tabs>
</template>

<script>
    import AllPhoto from '@/components/views/profile/AllPhoto.vue'
    import AlbumList from '@/components/views/profile/AlbumList.vue'
    export default {
        props: {
            username: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                photoCount: 0,
                albumCount: 0,
            }
        },
        components:{
            'all-photo': AllPhoto,
            'album': AlbumList,
        },
        mounted(){

        },
        methods:{
            photosLoaded(total){
                this.photoCount = total
            },
            albumsLoaded(total){
                this.albumCount = total
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>