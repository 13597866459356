<template>
    <v-row>
    <v-col md="12" lg="12" xl="8">
      <v-sheet class="fill-height">
      <div class="banner">
        <div class="cover-imange">
          <v-img height="250" :src="amI? loggedInUser.coverPicture.src:userInfo.coverPicture.src" aspect-ratio="2" @click="viewImage(0)"></v-img>
        </div>
        <v-col>
          <v-avatar size="135" class="avatar">
            <v-img :src="amI? loggedInUser.avatar.thumb:userInfo.avatar.thumb" @click="viewImage(1)"></v-img>
          </v-avatar>
          <div class="edit-button" >
              <template v-if="amI">
                <v-dialog
                    scrollable 
                    width="700"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="gray" v-on="on" rounded class="float-right">Chỉnh sửa hồ sơ</v-btn>
                    </template>
                    
                    <v-card>
                      <v-card-title class="headline grey lighten-2 justify-content-center" primary-title>
                        <span >Chỉnh sửa hồ sơ</span>
                      </v-card-title>

                      <v-card-text>
                        <editprofile></editprofile>
                      </v-card-text>

                    </v-card>
                  </v-dialog>
              </template>
          </div>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title class="title">{{amI?loggedInUser.firstname:this.userInfo.firstname}} {{amI? loggedInUser.lastname:this.userInfo.lastname}}</v-list-item-title>
              <p class="font-weight-medium bio my-3" >{{amI? loggedInUser.bio:userInfo.bio}}</p>
              <v-list-item-subtitle>
                <div class="d-inline mr-2"><v-icon small class="mr-1">mdi-cake-variant</v-icon>Sinh vào {{amI? loggedInUser.birthday:this.userInfo.birthday|formatFullDatetime}}</div>
                <div class="d-inline ml-2"><v-icon small class="mr-1">mdi-calendar</v-icon>Đã tham gia {{amI? loggedInUser.joinedDate:this.userInfo.joinedDate|formatYearMonth}}</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </div>
      <v-col class="py-0">
          <v-row class="border-top border-bottom">
            <v-col>
              <v-slide-group>
                <v-slide-item>
                  <router-link :to="`/userprofile/${userInfo.username}/timeline`">
                  <v-btn
                    class="mx-2"
                    text
                  >
                    Dòng thời gian
                  </v-btn>
                  </router-link>
                </v-slide-item>
                <v-slide-item>
                  <router-link :to="`/userprofile/${userInfo.username}/photos`">
                  <v-btn
                    class="mx-2"
                    text
                  >
                    Ảnh
                  </v-btn>
                  </router-link>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
          
      </v-col>
      </v-sheet>
    </v-col>
    <v-col class="sidebar" lg="2" xl="4"></v-col>
    <lightbox
        ref="lightbox"
        :media="[userInfo.coverPicture,userInfo.avatar]"
        :show-caption="false"
        :show-light-box="false"
        :showThumbs="false"
    />
  </v-row>
  
</template>

<script>
import EditProfile from '@/components/EditProfile'
import {UserService} from '@/services'
import LightBox from '@/components/imglightbox/LightBox.vue'
import {authComputed} from '@/helpers'

// require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
    data(){
      return {
        userInfo: {
          username: '',
          avatar: '',
          birthday: null,
          joinedDate: null,
          firstname: '', 
          lastname: '',
          coverPicture: '',
          bio: ''
        },
        postes: []
      }
    },
    mounted(){
        this.userInfo.username = this.$route.params.username
        Promise.all([this.loadUserInfor(), this.loadUserPost()]).then(resolves=>{
          let [userInfo, postes] = resolves
          this.userInfo  = userInfo
          this.postes = postes
        })
    },
    methods:{
      loadUserInfor(){
        return UserService.getUserInfo(this.userInfo.username)
      },
      loadUserPost(){
        return []
      },
      viewImage(image){
        this.$refs.lightbox.showImage(image)
      }
    },
    filters:{
      formatFullDatetime(val){
        if(!val){
          return ''
        }
        return 'ngày '+val.getDate()+' tháng '+(val.getMonth()+1)+' năm '+val.getFullYear()
      },
      formatYearMonth(val){
        if(!val){
          return ''
        }
        return 'tháng '+(val.getMonth()+1)+' năm '+val.getFullYear()
      }
    },
    components:{
      editprofile: EditProfile,
      lightbox: LightBox,
    },
    computed:{
      ...authComputed,
      amI(){
        return this.loggedInUser.username==this.$route.params.username
      }
    }
}
</script>

<style scoped>
.avatar{
  position: absolute;
  border: 4px solid white;
  margin-top: -78px;
}
.edit-button{
  min-height: 47px;
}
.bio{
  overflow-wrap: break-word; 
  max-width: 100%; 
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.95rem;
}
</style>