<template>
    <div class="d-flex">   
            <div class="avatar">
                <v-avatar :size="isTopLevel?40:25">
                    <v-img 
                        :src="data.user.avatar.thumb_sm"
                        alt="John" @error="onAvatarLoadFail"></v-img>
                </v-avatar>
            </div>
            <div class="content-box ml-2 rounded">
                <a :href="'/userprofile/'+data.user.username+'/'" class="username d-inline-block align-top">{{data.user.firstname}} {{data.user.lastname}}</a>
                <div class="d-inline-block">
                    <blockquote style="word-break: break-all;white-space: pre-wrap; ">{{data.content}}</blockquote>
                </div>

                <v-spacer></v-spacer>
                <div class="d-flex align-items-center">
                    <div class="action"  v-bind:class="data.liked===1?'liked': ''">
                        <v-btn :disabled="!loggedIn" 
                               v-bind:class="data.liked===1?'liked': ''"
                                icon @click="like">
                            <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <span class="counter mx-1">{{data.like}}</span>
                    </div>
                    <div class="action" v-if="isTopLevel">
                        <v-btn  :disabled="!loggedIn" 
                            icon 
                            @click="openCommentBox">
                            <v-icon>mdi-reply</v-icon>
                        </v-btn>
                        <span class="counter mx-1">{{data.replies.count}}</span>
                    </div>
                    <div class="ml-auto mr-2"><span class="duration">{{data.create_date | calDuration}}</span></div>
                </div>
                
            <comment-box v-model="quickcomment" @send="reply" :show="openQuickCommentBox"></comment-box>
        </div>
            
    </div>
</template>
<script>
import {authComputed} from '@/helpers'
import {CommentService, ReactionService} from '@/services'
import Commentbox from '@/components/CommentBox.vue'

export default {
    props:{
        isTopLevel: {type: Boolean, default: ()=> false},
        data:{
            type: Object,
            required: true,
            default:()=>({
                "id": null,
                "user": {
                    "username": "",
                    "last_name": "",
                    "first_name": "",
                    "experience": null,
                    "avatar": {}
                },
                "create_date": null,
                "update_date": null,
                "content": "nice",
                "target_id": "",
                like:0,
                replies: {count: 0},
                liked: -1,
            
            })
        }
    },
    components:{
        "comment-box": Commentbox
    },
    data(){
        return {
            openQuickCommentBox: false,
            quickcomment:'',
        }
    },
    computed:{
        ...authComputed,
    },
    methods:{
        like() {
            this.data.liked = this.data.liked * -1;
            this.data.like += this.data.liked;
            if(this.data.liked==-1){
                ReactionService.unLikeComment(this.data.id)
            }else{
                ReactionService.likeComment(this.data.id)
            }
            
        },
        openCommentBox() {
            this.openQuickCommentBox = !this.openQuickCommentBox;
        },
        reply(){
            CommentService.replyComment(this.data.id, this.quickcomment).then((response)=>{
                this.$emit("newreply", response)
                this.openQuickCommentBox = false;
            })
        },
        onAvatarLoadFail(){
            this.data.user.avatar = require('@/assets/default_avatar.jpg')
        }
    }
}
</script>
<style scoped>
.content-box{
    flex-grow: 1;
    background-color: #f8f9fa;
}
.avatar{
    vertical-align: top;
}
.duration{
    font-size: 13px;
}
</style>