import axios from 'axios'
import { Converter } from '../helpers/converters'

function uploadPhotos(photos){
    let data = {
        photo: photos.map(photo=>({photo}))
    }
    return axios.post("photos",data).then(response=>{
        return response.data.photo.photo
    })
}

function getUserPhotos(username, page) {
    return axios.get(`users/${username}/photos?page=`+page).then(res=>{
        let photos = []
        let data = res.data.photos.results
        for(let item of data){
            let photo = Converter.convertImage(item)
            photos.push(photo)
        }
        return {photos, hasNext: res.data.photos.next==true, count : res.data.photos.count }
    })
}

function getUserAlbums(username){
    return axios.get("albums",{params:{username}}).then(response=>{
        return {albums: response.data.albums.map(album=>_convertAlbums(album)), count: response.data.total}
    })
}
function _convertAlbums(input){
    return {
        name: input.name,
        id: input.id,
        cover: input.photo? input.photo.photo.thumbnail_medium: require('@/assets/no-image.png'),
        photoCount: input.total_photo||0
    }
}

function getPhotoOfAlbum(username, albumId, page){
    return axios.get(`users/${username}/album/${albumId}/photos?page=${page}`).then(res=>{
        let photos = []
        let data = res.data.photos.results
        for(let item of data){
            let photo = Converter.convertImage(item)
            photos.push(photo)
        }
        return {photos, hasNext: res.data.photos.next==true, count : res.data.photos.count }
    })
}

export const PhotoService = {
    uploadPhotos,
    getUserPhotos,
    getUserAlbums,
    getPhotoOfAlbum,

}