import moment from 'moment'
import Compressor from 'compressorjs';
import sanitizeHtml from 'sanitize-html'

export const DateUtils = {
    durationUntilNow,
}

export const FileUtils = {
    compress
}

//in second unit
const MINUTE = 60
const HOUR = MINUTE*60
const DAY = HOUR*24
const WEEK = DAY*7
const YEAR = WEEK*52


function durationUntilNow(date){
    let from = moment(new Date(date))
    let now = moment(new Date())
    
    let diffInSecond = now.diff(from, "seconds")
    let inYear = Math.floor(diffInSecond/YEAR)
    if(inYear>=1){
        return inYear+'y'
    }
    let inWeek = Math.floor(diffInSecond/WEEK)
    if(inWeek>=1){
        return inWeek+ 'w'
    }
    let inDay = Math.floor(diffInSecond/DAY)
    if(inDay>=1){
        return inDay+'d'
    }
    let inHour = Math.floor(diffInSecond/HOUR)
    if(inHour>=1){
        return inHour+'h'
    }
    let inMinute = Math.floor(diffInSecond/MINUTE)
    if(inMinute>=1){
        return inMinute+'m'
    }
    return 'Vừa xong'
}


function compress(file){
    return new Promise((resolve, reject)=>{
        new Compressor(file, {
        quality: 0.6,
        convertSize:3000000, //3MB
        success(result) {
            resolve(result)
        },
        error(err) {
            reject(err.message)
        }})
    })
}


export function sanitilizeHtml(rawHtml){
    let clean = sanitizeHtml(rawHtml, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img','oembed','figure']),
        allowedSchemesByTag: {
            img: [ 'data' ]
        },
        allowedAttributes: Object.assign(sanitizeHtml.defaults.allowedAttributes,{
            oembed:['url', 'class'],
            figure: ['class']
        })
    });

    return clean
}

export function embedToIframe(input){
    input = input.replace(/<oembed\s+url=/g, "<iframe src=")
    input = input.replace(/\/watch\?v=/g, '/embed/')
    input = input.replace(/<\/oembed>/g, "</iframe>")
    console.log(input);
    return input
}