<template>
  <v-select
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :items="choices"
    multiple
  >
    <template v-if="isDense" v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ item.text || item }}</span>
      <span v-if="index === 1" class="preserve-whitespace">
        (+{{ value.length - 1 }})
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="$emit('select-all', selectedAllChoices)"
      >
        <v-list-item-action>
          <v-icon
            :color="value.length > 0 ? 'indigo darken-4':''"
          >{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllTitle }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ['value', 'choices', 'label', 'isDense'],
  data () {
    return {
      selectAllTitle: 'Tất cả',
      selectedAllChoicesIcon: 'mdi-close-box',
      selectedSomeChoicesIcon: 'mdi-minus-box',
      selectedNoChoicesIcon: 'mdi-checkbox-blank-outline',
    }
  },
  computed: {
    selectedAllChoices () {
      return this.value.length === this.choices.length
    },
    selectedSomeChoices () {
      return this.value.length > 0 && !this.selectedAllChoices
    },
    icon () {
      if (this.selectedAllChoices) return this.selectedAllChoicesIcon
      if (this.selectedSomeChoices) return this.selectedSomeChoicesIcon
      return this.selectedNoChoicesIcon
    },
  },
}
</script>

<style scoped>
.preserve-whitespace {
  white-space: pre;
}
</style>
