<template>
  <v-row>
    <v-col lg="8" xl="8">
      <v-sheet class="pa-2 fill-height">
        <div> 
            <div class="d-inline-block">
                <user-box class="pl-0" :user="status.creator"></user-box>
            </div>
            <div class="owner-menu d-inline-block float-right" v-if="isOwner">
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                 <v-btn text class="w-100" @click="deleteStatus">
                                   Xóa
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div>
            <div class="description">{{status.content}}</div>
            <img-slider :images="status.photos" v-if="status.photos.length>0"  @click="showImage" class="my-2"></img-slider>
            <v-divider></v-divider>
            <v-card-actions>
                <div class="mx-auto action" v-bind:class="status.liked===1?'liked': ''">
                    <v-btn
                        :disabled="!loggedIn"
                        icon
                        v-bind:class="status.liked===1?'liked': ''"
                        @click="like"
                    >
                        <v-icon>mdi-heart</v-icon>
                    </v-btn>
                    <span class="counter">{{status.like}}</span>
                </div>
                <div  class="mx-auto action">
                    <v-btn :disabled="!loggedIn" icon @click="openCommentBox">
                        <v-icon>mdi-comment</v-icon>
                    </v-btn>
                    <span class="counter">{{status.comment}}</span>
                </div>
            </v-card-actions>
            <v-divider></v-divider>

            <comment-box v-model="quickcomment" @send="sendComment" :show="openQuickCommentBox" :height="3"></comment-box>
            
        </div>
        <comment-list :comments="status.comments" :targetId="status.id+''" @hasnewreply="hasNewReply"></comment-list>
      </v-sheet>
    </v-col>
    <v-col class="sidebar" lg="4" xl="4">

    </v-col>
  </v-row>
</template>

<script>
import CommentList from '@/components/views/schedule-detail/CommentList.vue'
import SmallUserBox from '@/components/SmallUserBox.vue'
import {StatusService, CommentService, ReactionService} from '@/services'
import {authComputed} from '@/helpers'
import Commentbox from '@/components/CommentBox.vue'
import ImageSlider from '@/components/imageviewbox/SlideView.vue'

// require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
    props: {
        id: {
            type: [String, Number],
            required: true
        },
    },
    data: function(){
        return {
            openQuickCommentBox: false,
            quickcomment: '',
            status: {
                id: null,
                createdDate: null,
                content: '',
                photos: [],

                comment: 0,
                like: 0,
                liked: -1,

                creator: {
                    username: "",
                    firstname: "",
                    lastname: "",
                    avatar: {},
                    level: ""
                },
                comments: {
                    hasNext: false,
                    itemsInPage: [],
                    page: 1, 
                    count: 0
                },
            }
        }
    },
    computed:{
        ...authComputed,
        isOwner(){
            return this.loggedInUser.username==this.status.creator.username
        },
    },
    components:{
        'comment-list': CommentList,
        'user-box': SmallUserBox,
        "comment-box": Commentbox,
        'img-slider': ImageSlider,
    },
    mounted(){
        this.status = this.$route.params.data
    },
    methods:{
        like() {
        this.status.liked = this.status.liked * -1;
        
        if(this.status.liked==-1){
            ReactionService.unlikeStatus(this.status.id).then(()=>{
                this.status.like-=1
            })
        }else{
            ReactionService.likeStatus(this.status.id).then(()=>{
                this.status.like+=1
            })
        }
        },
        openCommentBox() {
            this.openQuickCommentBox = !this.openQuickCommentBox;
        },
        hasNewReply(){
            this.status.comment+=1
        },
        sendComment() {
            CommentService.commentForStatus(this.status.id, this.quickcomment).then((res)=>{
                this.openQuickCommentBox = false;
                this.status.comment+=1
                this.status.comments.itemsInPage.push(res)
            })
        },
        deleteStatus(){
            StatusService.deleteStatus(this.status.id).then(()=>{
                this.$router.push("/")
            })
        },
        showImage(index){
            this.$refs.lightbox.showImage(index)
        },
    },
    beforeRouteEnter (to, from, next) {
        let id = to.params.id
        StatusService.getStatus(id).then(data=>{
           to.params.data = data
           next()
        })
    },
    beforeRouteUpdate (to, from, next) {
        let id = to.params.id
        StatusService.getStatus(id).then(data=>{
           to.params.data = data
           next()
        })
    },
}
</script>

<style scoped>
.content{
    min-height: 100%;
}
.image{
    max-height: 300px;
    width: auto;
    height: auto;
}
.image:hover {
    cursor: pointer;
}
.description{
    word-wrap: break-word;
    white-space: pre-wrap;
    /* text-align: justify;
    text-justify: inter-word; */
}
</style>