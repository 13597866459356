<template>
    <div>
    <v-dialog v-model="open"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on" style="text-decoration: underline; color: black">Tìm lại mật khẩu</span>
      </template>

      <v-card>
        <v-form v-model="valid">
        <v-card-title
          class="headline grey lighten-2" primary-title>
          Yêu cầu đặt lại mật khẩu
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field label="Email" required v-model="email"  :rules="emailRules"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        
        <v-card-text v-if="error">
          <span class="red--text">{{error}}</span>
        </v-card-text>
        <v-card-text v-if="success">
          <span class="green--text">Đã gửi yêu cầu thành công, vui lòng kiểm tra email của bạn</span>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-content-center">
          <v-btn @click.stop="send" :disabled="!valid">Gửi</v-btn>
          <!-- <v-btn x-small @click="open = false">Đóng</v-btn> -->
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { UserService } from "@/services";

export default {
    props:{
        open: Boolean
    },
    data(){
        return{
            valid: false,
            openForgotPassword: false,
            email: '',
            error: '',
            success: false,
            emailRules: [
              v => !!v || 'Email của bạn là gì?',
              v => /.+@.+\..+/.test(v) || 'E-mail chưa đúng định dạng',
            ],
        }
    },
    mounted(){
    },
    methods:{
        send(){
            UserService.sendForgotPasswordRequest(this.email).then(()=>{
                this.success = true;
                setTimeout(() => {
                    this.open = false;    
                }, 1000);
            }, error=>{
              let errors = error.response.data
              this.error = ''
              for(let key in errors){
                this.error += errors[key]
              }
            })
            
        },
        clear(){
            this.error = ''
            this.email = ''
            this.success= false
        }
    }
}
</script>

<style scoped>
</style>