<template>

    <v-row class="album-list">
        <span v-if="albums.length==0">Xin lỗi, chúng tôi không tìm thấy album nào</span>
        <v-col cols="3" v-for="(album, index) in albums" :key="index">
            <router-link :to="{ path: `album/${album.id}`, query: $route.query }">
                <v-card class="mx-auto" flat tile>
                    <v-img
                        :src="album.cover"
                        aspect-ratio="1"
                    >
                    </v-img>
                    <v-card-title>
                        {{album.name}}
                    </v-card-title>
                    <v-card-subtitle>
                        {{album.photoCount}} ảnh
                    </v-card-subtitle>
                </v-card>
            </router-link>
        </v-col>
    </v-row>

</template>

<script>
    import {PhotoService} from "@/services"
    export default {
        props:{
            username:{
                type: String,
                required: true
            }
        },
        data:()=>{
            return {
                albums: [],
            }
        },
        mounted(){
            PhotoService.getUserAlbums(this.username).then(res=>{
                this.albums = res.albums
                this.$emit("loaded", res.count)
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>