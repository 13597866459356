<template>
  <v-row no-gutters>
      <v-col v-for="(image,index) in images" :key="index" cols="4">
        <v-img  aspect-ratio="1" @click="$emit('click', index)"
            :src="image.src" ></v-img>
      </v-col>
  </v-row>

</template>

<script>
export default {
    props:{
      images: {
        type: Array,
        required: true
      }
    }
}
</script>

<style scoped>
.img{
  margin:1px;
  flex-grow:1;
  flex-shrink:0;
}
</style>