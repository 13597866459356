<template>
    <v-row no-gutters>
        <v-col
            v-for="(image,index) in images.slice(0,2)"
            :key="index"
            :cols="12"
        >
            <v-img :src="image.thumb_md" :aspect-ratio="2">
            </v-img>
            <v-overlay absolute v-if="images.length>2&& index ==1">
                <v-icon>+{{images.length-2}}</v-icon>
            </v-overlay>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            default: ()=>([])
        },
    },
}
</script>

<style scoped>

</style>