<template>
  <v-row>
    <v-col md="12" lg="8">
      <v-sheet class="content pa-2">
        <div> 
            <div class="d-inline-block"><user-box class="pl-0" :user="scheduleInfo.creator"></user-box></div>
            <div class="owner-menu d-inline-block float-right" v-if="isOwner && canUpdate">
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                 <v-btn text class="w-100" @click="$router.push(`/schedule-create/${scheduleInfo.id}`)">
                                   Sửa
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                 <v-btn text class="w-100" @click="closeSchedule">
                                   Chốt đoàn
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                 <v-btn text class="w-100" @click="cancelSchedule">
                                   Hủy chuyến đi
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div>
            <h3>{{scheduleInfo.name}}</h3>
            <h4>{{scheduleInfo.startPoint}}~{{scheduleInfo.endPoint}}</h4>
            <h5>Khởi hành {{scheduleInfo.startDate |localDatetime}} về {{scheduleInfo.endDate|localDatetime}}</h5>
            <div>Độ khó cung đường: {{scheduleInfo.challengeLevel|formatChallengeLevel}}</div>
            <div>Phương tiện: {{scheduleInfo.vehicleType |formatVehicle}}</div>
            <br>
            <div class="description">{{scheduleInfo.content}}</div>
            
            <image-grid-view :images="scheduleInfo.images" @click="showImage"></image-grid-view> 
            
            <v-chip-group >
                <v-chip v-if="scheduleInfo.allowDriver>0">Tuyển xế({{scheduleInfo.allowDriver}})</v-chip>
                <v-chip v-if="scheduleInfo.allowAttachment>0">Tuyển ôm({{scheduleInfo.allowAttachment}})</v-chip>
                <v-chip v-if="scheduleInfo.budget>0">Mất phí({{scheduleInfo.budget|formatCurrency}})</v-chip>
            </v-chip-group>
            <v-row></v-row>
            <div v-if="!isOwner&& canUpdate">
                <send-join-request v-if="loginUserStatus == 'NOT_REQUEST'" @send-request="requestJoin"></send-join-request>
                <v-btn
                    v-if="loginUserStatus == 'REQUESTED'" :disabled="!loggedIn"
                    @click="CancelRequest"
                    class="mb-2 w-100" outlined>
                    Hủy yêu cầu đăng ký tham gia
                </v-btn>
                <v-btn
                    v-if="loginUserStatus == 'JOINED'" :disabled="!loggedIn"
                    @click="leaveSchedule"
                    class="mb-2 w-100" outlined>
                    Rút khỏi lịch trình
                </v-btn>
            </div>

            <v-divider></v-divider>
            <v-card-actions>
                <div class="mx-auto action" v-bind:class="scheduleInfo.liked===1?'liked': ''">
                    <v-btn
                        :disabled="!loggedIn"
                        icon
                        v-bind:class="scheduleInfo.liked===1?'liked': ''"
                        @click="like"
                    >
                        <v-icon>mdi-heart</v-icon>
                    </v-btn>
                    <span class="counter">{{scheduleInfo.like}}</span>
                </div>
                <div  class="mx-auto action">
                    <v-btn :disabled="!loggedIn" icon @click="openCommentBox">
                        <v-icon>mdi-comment</v-icon>
                    </v-btn>
                    <span class="counter">{{scheduleInfo.comments.count}}</span>
                </div>
                <div  class="mx-auto action" v-bind:class="scheduleInfo.followed===1?'followed':''">
                    <v-btn
                        :disabled="!loggedIn"
                        icon
                        v-bind:class="scheduleInfo.followed===1?'followed':''"
                        @click="follow"
                    >
                        <v-icon>mdi-bookmark</v-icon>
                    </v-btn>
                    <span class="counter">{{scheduleInfo.follow}}</span>
                </div>
                <div class="mx-auto action">
                    <v-btn @click="shareFace" icon>
                        <v-icon>mdi-share</v-icon>
                    </v-btn>
                </div>
            </v-card-actions>
            <v-divider></v-divider>

            <comment-box v-model="quickcomment" @send="sendComment" :show="openQuickCommentBox" :height="3"></comment-box>
            
        </div>
          <comment-list :comments="scheduleInfo.comments" @hasnewreply="hasNewReply" :targetId="scheduleInfo.id+''"></comment-list>
      </v-sheet>
    </v-col>
    <v-col class="sidebar" md="12" lg="4">
        
        <v-row>
            <v-sheet>
            <v-col lg="12" xl="11">
                
                <div class="schedule-detail" v-if="scheduleInfo.points.length>0">
                    <timeline :points="scheduleInfo.points"></timeline>
                </div>
                <br>
                <v-tabs show-arrows >
                    <v-tab  href="#requesting">
                        Chờ phê duyệt({{this.scheduleInfo.requestingUsers.length}})
                    </v-tab>
                    <v-tab-item value="requesting">
                        <requesting-users   :schedule_id="scheduleInfo.id+''" 
                                            :users="scheduleInfo.requestingUsers" 
                                            :hasPermission="isOwner&&canUpdate"
                                            @request-approved="userRequestApproved"
                                            @request-rejected="userRequestRejected">
                        </requesting-users>
                    </v-tab-item> 

                    <v-tab href="#members" >
                        Thành viên({{this.scheduleInfo.members.length}})
                    </v-tab>
                    <v-tab-item  value="members">
                        <member-list :schedule_id="scheduleInfo.id+''" 
                                    :members="scheduleInfo.members"
                                    :hasPermission="isOwner&&canUpdate"
                                    @member-removed="memberRemoved">
                        </member-list>
                    </v-tab-item>
                </v-tabs>
               
            </v-col>
            </v-sheet>
        </v-row>
        
    </v-col>

     <LightBox
        ref="lightbox"
        :media="scheduleInfo.images"
        :show-caption="false"
        :show-light-box="false"
    />

  </v-row>
</template>

<script>
import Timeline from '@/components/views/schedule-detail/Timeline.vue'
import CommentList from '@/components/views/schedule-detail/CommentList.vue'
import SmallUserBox from '@/components/SmallUserBox.vue'
import MemberList from '@/components/views/schedule-detail/MemberList.vue'
import RequestingUsers from '@/components/views/schedule-detail/RequestingUsers.vue'
import JoinRequest from '@/components/views/schedule-detail/JoinRequest.vue'
import {ScheduleService, CommentService, ReactionService} from '@/services'
import {authComputed} from '@/helpers'
import {UserService} from "@/services"
import Commentbox from '@/components/CommentBox.vue'
import LightBox from '@/components/imglightbox/LightBox.vue'
import ImageGridView from '@/components/imageviewbox/GridLayout.vue'

// require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
    props: {
        data: {
            type: Object,
            default:()=> ({
                id: '',
                createdDate: null,
                startDate: null,
                endDate: null,
                content: '',
                startPoint: '',
                name: '',
                images: [],
                allowAttachment: 0,
                allowDriver: 0,
                budget: 0,
                status: null,
                challengeLevel: '',
                like:0,
                follow:0,
                creator:{},
                
                points: [],
                requestingUsers: [],
                members:[],
                comments:{
                    hasNext: false, 
                    page: 1, 
                    itemsInPage: [],
                    count: 0
                },

                liked:-1,
                followed: -1,
                // loginUserStatus: 'NOT_REQUEST' 
            }),
        },
    },
    data: function(){
        return {
            scheduleInfo:{
                id: '',
                createdDate: null,
                startDate: null,
                endDate: null,
                content: '',
                startPoint: '',
                endPoint: '',
                name: '',
                images: [],
                allowAttachment: 0,
                allowDriver: 0,
                budget: 0,
                status: null,
                challengeLevel: '',
                like:0,
                follow:0,
                creator:{},
                
                points: [],
                requestingUsers: [],
                members:[],
                comments:{
                    hasNext: false, 
                    page: 1, 
                    itemsInPage: [],
                    count: 0
                },

                liked:-1,
                followed: -1,
                // loginUserStatus: 'NOT_REQUEST' 
            },
            openQuickCommentBox: false,
            quickcomment: ''

        }
    },
    computed:{
        ...authComputed,
        isOwner(){
            return this.loggedInUser.username==this.scheduleInfo.creator.username
        },
        canUpdate(){
            return this.scheduleInfo.status=='HIRING'
        },
        loginUserStatus(){
            /**NOT_REQUEST, REQUESTED , JOINED  */
            if(!this.loggedIn){
                return "NOT_REQUEST"
            }
            if(this.scheduleInfo.members.filter(mem=>mem.username == this.loggedInUser.username).length>0){
                return "JOINED"
            }
            if(this.scheduleInfo.requestingUsers.filter(user=>user.username == this.loggedInUser.username).length>0){
                return "REQUESTED"
            }
            return "NOT_REQUEST"
        }
    },
    components:{
        timeline: Timeline,
        'comment-list': CommentList,
        'user-box': SmallUserBox,
        'member-list': MemberList,
        'requesting-users':RequestingUsers,
        'send-join-request': JoinRequest,
        "comment-box": Commentbox,
        'image-grid-view': ImageGridView,
        LightBox
    },
    mounted(){
        this.scheduleInfo = Object.assign({}, this.$route.params.data)
    },
    methods:{
        like() {
            this.scheduleInfo.liked = this.scheduleInfo.liked * -1;
            
            if(this.scheduleInfo.liked==-1){
                ReactionService.unlikeSchedule(this.scheduleInfo.id).then(()=>{
                })
            }else{
                ReactionService.likeSchedule(this.scheduleInfo.id).then(()=>{
                })
            }
            this.scheduleInfo.like += this.scheduleInfo.liked;
        },
        openCommentBox() {
            this.openQuickCommentBox = !this.openQuickCommentBox;
        },
        sendComment() {
            CommentService.commentForSchedule(this.scheduleInfo.id, this.quickcomment).then((res)=>{
                this.openQuickCommentBox = false;
                this.scheduleInfo.comments.count+=1
                this.scheduleInfo.comments.itemsInPage.push(res)
                this.quickcomment= ''
            })
        },
        hasNewReply(){
            this.scheduleInfo.comments.count+=1
        },
        shareFace(){
            let url = process.env.BASE_URL+"detail/"+this.scheduleInfo.id
            /*global FB*/
            FB.ui({
                method: 'share',
                href: url,
            }, function(){});
        },
        follow() {
            this.scheduleInfo.followed = this.scheduleInfo.followed * -1;
            
            if(this.scheduleInfo.followed==-1){
                ReactionService.unfollowSchedule(this.scheduleInfo.id).then(()=>{
                })
            }else{
                ReactionService.followSchedule(this.scheduleInfo.id).then(()=>{
                })
            }
            this.scheduleInfo.follow += this.scheduleInfo.followed;
        },
        requestJoin({option, message}){
            switch(option){
                case "DRIVER":
                    UserService.requestJoinAsDriver(this.scheduleInfo.id, message|"hi").then(()=>{
                        let newUser = Object.assign({isDriver: true}, this.loggedInUser)
                        this.scheduleInfo.requestingUsers.push(newUser)
                    })
                    break;
                case "ATTACHMENT": 
                    UserService.requestJoinAsAttachment(this.scheduleInfo.id, message|"hi").then(()=>{
                        let newUser = Object.assign({isAttachment: true}, this.loggedInUser)
                        this.scheduleInfo.requestingUsers.push(newUser)
                    })
                    break;
                case "COUPLE": 
                    UserService.requestJoinAsCouple(this.scheduleInfo.id, message|"hi").then(()=>{
                        let newUser = Object.assign({isCouple: true}, this.loggedInUser)
                        this.scheduleInfo.requestingUsers.push(newUser)
                    })
                    break;
            }
        },
        leaveSchedule(){
            UserService.leaveSchedule(this.scheduleInfo.id).then(()=>{
                this.scheduleInfo.members = this.scheduleInfo.members.filter((mem)=>{
                    return mem.username != this.loggedInUser.username
                })
            })
        },
        CancelRequest(){
            UserService.leaveSchedule(this.scheduleInfo.id).then(()=>{
                this.scheduleInfo.requestingUsers = this.scheduleInfo.requestingUsers.filter((user)=>{
                    return user.username != this.loggedInUser.username
                })
            })
        },
        userRequestApproved(mem){
            this.scheduleInfo.requestingUsers = this.scheduleInfo.requestingUsers.filter(function(u){
                return u.username != mem.username
            })
            this.scheduleInfo.members.push(mem)
        },
        userRequestRejected(rejected){
            this.scheduleInfo.requestingUsers = this.scheduleInfo.requestingUsers.filter(function(u){
                return u.username != rejected.username
            })
        },
        memberRemoved(mem){
            this.scheduleInfo.members = this.scheduleInfo.members.filter(function(u){
                return u.username != mem.username
            })
        },
        closeSchedule(){
            ScheduleService.closeSchedule(this.scheduleInfo.id).then(()=>{
                location.reload()
            })
        },
        cancelSchedule(){
            ScheduleService.cancelSchedule(this.scheduleInfo.id).then(()=>{
                this.$router.push("/")
            })
        },
        showImage(index){
            this.$refs.lightbox.showImage(index)
        },
    },
    filters:{
        formatChallengeLevel(level){
            switch(level){
                case 1:
                    return 'Đường dễ đi';
                case 2:
                    return 'Chạy nhẹ nhàng';
                case 3: 
                    return 'Đường khó đi';
                case 4: 
                    return 'Cung đường thử thách'
                default:
                    return ''
            }
        },
        formatVehicle(input){
            switch(input){
                case "BIKE":
                    return "Xe đạp";
                case "MOTOR":
                    return "Xe máy";
                case "CAR":
                    return "Ô tô";
                default:
                    return "Không xác định"
            }
        }
    }, 
    beforeRouteEnter (to, from, next) {
        let id = to.params.id
        ScheduleService.getScheduleDetail(id).then(data=>{
            to.params.data = data
            next()
        })
    },
    beforeRouteUpdate (to, from, next) {
        let id = to.params.id
        ScheduleService.getScheduleDetail(id).then(data=>{
            to.params.data = data
            next()
        })
    },
}
</script>

<style scoped>
.image:hover {
    cursor: pointer;
}
.description{
    word-wrap: break-word;
    white-space: pre-wrap;
    /* text-align: justify;
    text-justify: inter-word; */
}
</style>