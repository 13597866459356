<template>
    <div >
      <div v-if="members.length >0">
        <div>Số lượng xế:{{driverCount}}</div>
        <div>Số lượng ôm:{{attachmentCount}}</div>
      </div>
    
    <v-list two-line flat >
      <v-list-item-group v-for="(member,index) in members" :key="index">
        <v-row no-gutters>
            <v-col cols="1"  class="d-flex align-items-center">
                <v-tooltip bottom v-if="member.isDriver">
                  <template v-slot:activator="{ on }">
                     <v-img :src="require('@/assets/icon/single_driver.png')" v-on="on" max-width="40" max-height="40"></v-img>
                  </template>
                  <span>Xế cô đơn</span>
                </v-tooltip>
                <v-tooltip bottom v-if="member.isAttachment">
                  <template v-slot:activator="{ on }">
                    <v-icon medium color="black" v-on="on">mdi-hail</v-icon>
                  </template>
                  <span>Ôm lẻ bóng</span>
                </v-tooltip>
                <v-tooltip bottom v-if="member.isCouple">
                  <template v-slot:activator="{ on }">
                     <v-img :src="require('@/assets/icon/couple.png')"  v-on="on" max-width="40" max-height="40"></v-img>
                  </template>
                  <span>Có đôi có cặp</span>
                </v-tooltip>
             
            </v-col>
            <v-col ><user-box :user="member"></user-box></v-col>
            <v-col cols="1" class="d-flex align-items-center">
              <div v-if="member.isOwner">
                <v-tooltip bottom v-if="member.isDriver">
                  <template v-slot:activator="{ on }">
                     <v-icon v-on="on">mdi-stamper</v-icon>
                  </template>
                  <span>Người khởi xướng</span>
                </v-tooltip>
              </div>
              <template v-else>
                  <v-btn icon v-if="hasPermission" @click="removeMember(member)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
              </template>
             
            </v-col>
        </v-row>
      </v-list-item-group>
      </v-list>
    </div>
</template>

<script>
import SmallUserBox from '@/components/SmallUserBox.vue'
import {UserService} from '@/services'

export default {
    props:{
      schedule_id: {
        type: String,
        default: ''
      },
      members:{
        type: Array,
        default: ()=>([])
      },
      hasPermission: {
        type: Boolean, 
        default: false
      }
    },
    components:{
      'user-box': SmallUserBox
    },
    computed:{
      driverCount(){
        let count = 0;
        for(let mem of this.members){
          if(mem.isDriver||mem.isCouple){
            count++
          }
        }
        return count
      },
      attachmentCount(){
        let count = 0;
        for(let mem of this.members){
          if(mem.isAttachment||mem.isCouple){
            count++
          }
        }
        return count
      },
    },
    methods:{
      removeMember(member){
        UserService.removeMember(this.schedule_id,member.username).then(()=>{  
          this.$emit("member-removed",member)
        })
      }
    }
  }
</script>

<style scoped>

</style>