<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn
            class="mb-2 w-100" outlined :disabled="!loggedIn"
            v-on="on">
            Đăng ký tham gia
        </v-btn>
      </template>

      <v-card>
        <v-card-title primary-title>
          Bạn là....
        </v-card-title>

        <v-card-text>
             <v-btn
                color="primary"
                class="mb-2 w-100"
                :disabled="!loggedIn"
                @click="sendRequest('DRIVER')"> 
                Xế cô đơn
            </v-btn>
            <v-btn
                color="primary"
                class="mb-2 w-100"
                :disabled="!loggedIn"
                @click="sendRequest('ATTACHMENT')">
                Ôm lẻ bóng
            </v-btn>
            <v-btn
                color="primary"
                class="mb-2 w-100"
                :disabled="!loggedIn"
                @click="sendRequest('COUPLE')">
                Có đôi có cặp
            </v-btn>
             <v-textarea
                solo
                label="Đôi lời về bạn"
                counter="200"
                v-model="message"
            ></v-textarea>
        </v-card-text>

      </v-card>
    </v-dialog>
</template>

<script>
import {authComputed} from '@/helpers'
  export default {
    data () {
      return {
        dialog: false,
        message: ''
      }
    },
    computed:{
        ...authComputed
    },
    methods:{
        sendRequest(option){
            this.dialog=false
            this.$emit("send-request", {option, message: this.message})
        }
    }
  }
</script>