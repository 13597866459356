<template>
    <div>
        <router-link to="/">
        <v-app-bar app color="gray" flat>
            <v-list-item-avatar>
              <img src="@/assets/logo.png">
            </v-list-item-avatar>
            <v-toolbar-title>Mê xê dịch</v-toolbar-title>
        </v-app-bar>
        </router-link>

        
        <v-container class="fill-height">
            <v-row>
            <v-col>
                <router-view></router-view>
            </v-col>
            </v-row>
        </v-container>
   
        <v-footer color="gray" app >
            <span class="black--text">&copy; 2020</span>
        </v-footer>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>