<template>
    <div>
        <v-img v-if="images.length ==1" :src="images[0].thumb_md"   :aspect-ratio="1"></v-img>
        <component :is="imageView" :images="images"></component>
    </div>
</template>

<script>
    import SideBySide from '@/components/imageviewbox/SideBySide.vue'
    import TopBottom from '@/components/imageviewbox/TopBottom.vue'
    import OneOnTopTwo from '@/components/imageviewbox/OneOnTopTwo.vue'
    import OneBesideTwo from '@/components/imageviewbox/OneBesideTwo.vue'
    import OneOnTopThree from '@/components/imageviewbox/OneOnTopThree.vue'
    import OneBesideThree from '@/components/imageviewbox/OneBesideThree.vue'
    import FourSquare from '@/components/imageviewbox/FourSquare.vue'
    import OneOnTopFour from '@/components/imageviewbox/OneOnTopFour.vue'
    import TwoBesideThree from '@/components/imageviewbox/TwoBesideThree.vue'
    import TwoOnTopThree from '@/components/imageviewbox/TwoOnTopThree.vue'



    export default {
        props:{
            images: {
                type: Array, 
                required: true
            }
        },
        components:{
            'SideBySide': SideBySide,
            'TopBottom': TopBottom,
            'OneOnTopTwo': OneOnTopTwo,
            'OneBesideTwo': OneBesideTwo,
            'OneOnTopThree': OneOnTopThree,
            'OneBesideThree': OneBesideThree,
            'FourSquare': FourSquare,
            'OneOnTopFour': OneOnTopFour,
            'TwoBesideThree': TwoBesideThree,
            'TwoOnTopThree': TwoOnTopThree,
        },
        computed:{
            imageView(){
                let rand = Math.floor(Math.random()*100);
                let viewer = [];
                if(this.images.length>=5){
                    viewer = ["OneOnTopTwo","OneBesideTwo","OneOnTopThree","OneBesideThree","FourSquare","OneOnTopFour","TwoBesideThree","TwoOnTopThree"]
                }
                else if(this.images.length==4){
                    viewer = ["OneOnTopTwo","OneBesideTwo","OneOnTopThree","OneBesideThree","FourSquare"]
                }
                else if(this.images.length==3){
                    viewer = ["OneOnTopTwo","OneBesideTwo"]
                }
                else if(this.images.length==2){
                    viewer = ["SideBySide","TopBottom"]
                }
                return viewer[rand% viewer.length]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>