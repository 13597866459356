<template>
        <v-row no-gutters>
            <v-col
              v-for="(image, index) in images.slice(0,5)"
              :key="index"
              :cols="index==0?12:3"
            >
                <v-img
                  :src="image.thumb_md"
                  :aspect-ratio="index==0?2:1"
                >
                </v-img>
                <v-overlay absolute v-if="images.length>5&& index ==4">
                    <v-icon>+{{images.length-5}}</v-icon>
                </v-overlay>
            </v-col>
        </v-row>
</template>

<script>
    export default {
        props: {
            images: {
                type: Array,
                default: ()=>([])
            },
        },
    }
</script>

<style scoped>

</style>