<template>
    <div>
    <v-dialog v-model="openDialog"
      max-width="700px"
    >
      <template v-slot:activator="{ on }">
        <v-btn class="w-100" v-on="on">
          Đăng nhập
        </v-btn>
      </template>

      <v-card>
        <v-form v-model="valid">
        <v-card-title
          class="headline grey lighten-2 mb-5" primary-title>
          Kết nối đam mê!
        </v-card-title>

        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="6" class="border-right">
              <v-text-field label="Tên đăng nhập" tabindex = "1" v-model="username" :rules="usernameRules" autofocus=""></v-text-field>
              <v-text-field label="Mật khẩu" tabindex="2" type="password" 
              v-model="password" :rules="passwordRules" @keyup.enter="login"></v-text-field>
              <a href="" @click="openForgotPassword = true"></a>
            </v-col>
            <v-col cols="6">
              <v-card
                elevation="2"
                class="mb-4"
                @click="loginWithFacebook"
              >
                <v-card-text>
                  <v-icon>mdi-facebook</v-icon>
                  Đăng nhập bằng Facebook
                </v-card-text>
              </v-card>
              <v-card
                elevation="2"
                @click="loginWithGoogle"
              >
                <v-card-text>
                  <v-icon>mdi-google</v-icon>
                  Đăng nhập bằng Google
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <request-reset-pass tabindex="4" :open="openForgotPassword">Tìm lại mật khẩu</request-reset-pass>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="error">
          <span class="red--text">{{error}}</span>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="gray" depressed @click="login" :disabled="!valid" class="w-100">
            Đăng nhập
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RequestResetPass from '@/components/user/RequestResetPassword'

export default {
  data(){
      return{
        valid: false,
        openForgotPassword:false,
        openDialog: false,
        username: '',
        password: '',
        error: '',
        usernameRules: [
          v => !!v || 'Bạn hãy nhập tên đăng nhập?',
        ],
        passwordRules: [
          v => !!v || 'Bạn hãy nhập mật khẩu?',
        ],
      }
  },
  methods:{
    login(){
      const {username, password} = this
      this.$store.dispatch('user/login',{username, password}).then(()=>{
        this.openDialog = false
        this.error =''
      }).catch(error=>{
        // console.log(error);
        let errors = error.response.data
        this.error = ''
        for(let key in errors){
          this.error += errors[key]
        }
         this.openDialog = true
      })
    },

    loginWithFacebook () {
      const clientId = process.env.VUE_APP_SNS_FACEBOOK_CLIENT_ID
      const redirectUri = process.env.VUE_APP_SNS_FACEBOOK_REDIRECT_URI
      const url = `http://graph.facebook.com/oauth/authorize?client_id=${clientId}&scope=email&redirect_uri=${redirectUri}&response_type=token`
      window.location.href = url
    },

    loginWithGoogle () {
      const clientId = process.env.VUE_APP_SNS_GOOGLE_CLIENT_ID
      const redirectUri = process.env.VUE_APP_SNS_GOOGLE_REDIRECT_URI
      const url = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&prompt=consent&response_type=code&client_id=${clientId}&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&access_type=offline`
      window.location.href = url
    },
  },
  components:{
    'request-reset-pass': RequestResetPass
  }
}
</script>

<style scoped>
remember-me label{
  margin-bottom: 0px !important;
}
</style>