const state = {
  
}


const mutations = {
  
}
const actions = {
  
}

export const Global = {
  state, 
  mutations, 
  actions
}