<template>
  <v-card class="mb-2" flat outlined >
    <user-box :user="status.creator">
      <template v-slot:after-subtitle>{{status.createdDate|formatCreatedDate}}</template>
    </user-box>
    <div class="detail" @click="goDetail">
    <v-card-text class="content">
        <span>{{status.content.substring(0,300)}}</span>
        <template v-if="status.content.length >300">
            <span v-if="!seeMoreContent">...</span>
            <span class="remaining" v-if="seeMoreContent">{{status.content.substring(300)}}</span>
            <br>
            <a href="" v-on:click.stop.prevent="seeMoreContent=true" v-if="!seeMoreContent">Đọc thêm</a>
            <a href="" v-on:click.stop.prevent="seeMoreContent=false" v-if="seeMoreContent">Thu gọn</a>
        </template>
        <br>
    </v-card-text>

    <img-set :images="status.photos"></img-set>

    </div>
    <v-divider></v-divider>
    <v-card-actions>
      <div class="mx-auto action" v-bind:class="status.liked===1?'liked': ''">
        <v-btn
          :disabled="!loggedIn"
          icon
          v-bind:class="status.liked===1?'liked': ''"
          @click="like"
        >
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <span class="counter">{{status.like}}</span>
      </div>
      
      <div class="mx-auto action" >
        <v-btn :disabled="!loggedIn" icon @click="openCommentBox">
          <v-icon>mdi-comment</v-icon>
        </v-btn>
        <span class="counter">{{status.comment}}</span>
      </div>
    </v-card-actions>

    <comment-box v-model="quickcomment" @send="sendComment" :show="openQuickCommentBox" :height="3"></comment-box>
  </v-card>
</template>

<script>
import SmallUserBox from "@/components/SmallUserBox.vue";
import {authComputed } from "@/helpers";
import {ReactionService, CommentService} from '@/services'
import Commentbox from '@/components/CommentBox.vue'
import PreviewImgSet from '@/components/imageviewbox/PreviewImgSet.vue'

import moment from 'moment'
const MINUTE = 60
const HOUR = MINUTE*60
const DAY = HOUR*24

export default {
  props: {
    status: {
      type: Object,
      required: true,
      default: ()=>({
        id: null,
        createdDate: null,
        content: '',
        photos: [],

        comment: 0,
        like: 0,
        liked: -1,

        creator: {
            username: "",
            firstname: "",
            lastname: "",
            avatar: null,
            level: ""
        }
      })
    },
  },
  data: () => {
    return {
      openQuickCommentBox: false,
      quickcomment: "",
      seeMoreContent: false, 
    };
  },
  computed: {
    ...authComputed,
  },
  components: {
    "user-box": SmallUserBox,
    "comment-box": Commentbox,
    'img-set': PreviewImgSet,

  },
  filters:{
       formatCreatedDate(date){
           let from = moment(new Date(date))
            let now = moment(new Date())
            
            let diffInSecond = now.diff(from, "seconds")

            let inDay = Math.floor(diffInSecond/DAY)
            if(inDay>=1){
               return new Date(date).toLocaleDateString('vi-VN')
            }
            let inHour = Math.floor(diffInSecond/HOUR)
            if(inHour>=1){
                return inHour+' giờ trước'
            }
            let inMinute = Math.floor(diffInSecond/MINUTE)
            if(inMinute>=1){
                return inMinute+' phút trước'
            }
            return 'Vừa xong'
       }
  },
  methods: {
    like() {
      this.status.liked = this.status.liked * -1;
      
      if(this.status.liked==-1){
        ReactionService.unlikeStatus(this.status.id).then(()=>{
            this.status.like-=1
        })
      }else{
        ReactionService.likeStatus(this.status.id).then(()=>{
            this.status.like+=1
        })
      }
    },
    openCommentBox() {
      this.openQuickCommentBox = !this.openQuickCommentBox;
    },
    sendComment() {
      CommentService.commentForStatus(this.status.id, this.quickcomment).then(()=>{
        this.openQuickCommentBox = false;
        this.status.comment+=1
      })
    },
    goDetail(){
      this.$router.push(`status/${this.status.id}`)
    }
  },
};
</script>
<style scoped>
.content{
  /* text-align: justify;
  text-justify: inter-word; */
  word-break: break-all;
  white-space: pre-wrap;
}
.detail:hover{
    cursor: pointer;
}

</style>