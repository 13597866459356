/**
 * The helpers index file groups all helper exports together 
 * so they can be imported in other parts of the app using only the folder path,
 * and enables importing multiple helpers in a single statement (e.g. import { helper1, helper2, ... } from '../_helpers').
 */

export * from './auth-header'
export * from './converters'
export * from './utils'
export * from './places'
export * from './ckeditor/UploadAdaper'
export * from './ckeditor/ClassicEditor'

import {mapGetters} from 'vuex'

export const authComputed = {
    ...mapGetters({
        loggedIn : 'user/loggedIn',
        loggedInUser:'user/loggedInUser'
    })
}