const vue_fb = {}
vue_fb.install = function install(Vue, options) {
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function onSDKInit() {
        /*global FB*/
        FB.init(options)
        FB.AppEvents.logPageView(); 
    }
}

import Vue from 'vue'

Vue.use(vue_fb, {
    appId            : process.env.VUE_APP_FACEBOOK_APP_ID,
    autoLogAppEvents : true,
    xfbml            : true,
    version          : 'v7.0'
})
