<template>
  <v-row no-gutters>
    <v-spacer></v-spacer>
    <v-col lg="12" xl="6" class=" d-flex flex-column" style="position:sticky;top:0px;max-height:100vh;">
          <v-col style="min-width: 100%;">
            <v-list class="menu">
                <router-link to="/">
                <v-list-item two-line >
                  <v-list-item-avatar>
                      <img src="@/assets/logo.png">
                  </v-list-item-avatar>

                  <v-list-item-content>
                      <v-list-item-title>Mê xê dịch</v-list-item-title>
                      <v-list-item-subtitle>Kết nối đam mê</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                </router-link>

                <v-list-item v-for="item in items" 
                            :key="item.title" :to="item.link" @click.native="reload"
                            :disabled="item.requireAuth&& !loggedIn">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                        <v-badge v-if="item.hasBadges && loggedInUser.hasNewNoti" color="black" dot >
                        </v-badge>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-btn depressed class="w-100 d-md-none d-lg-flex" :disabled="!loggedIn" rounded to="/schedule-create">Tạo chuyến đi</v-btn>
                </v-list-item>
                <!-- <v-list-item>
                  <v-btn depressed class="w-100 d-md-none d-lg-flex" :disabled="!loggedIn" rounded to="/review">Viết Review</v-btn>
                </v-list-item> -->
            </v-list>
          </v-col>

          <v-spacer></v-spacer>

          <v-col  cols="1" v-if="loggedIn" style="min-width: 100%;"  class="logginUser align-bottom">
              <v-list >
                <v-list-item >
                  <router-link :to="'/userprofile/'+ loggedInUser.username +'/timeline'">
                    <v-list-item-avatar>
                            <v-img :src="loggedInUser.avatar"></v-img>
                    </v-list-item-avatar>
                  </router-link>
                  <v-list-item-content>
                      <v-row no-gutters>
                      <v-col cols="8">
                        <router-link :to="'/userprofile/'+ loggedInUser.username +'/timeline'">
                          <span class="username">{{loggedInUser.firstname+' '+loggedInUser.lastname}}</span>
                        </router-link>
                      </v-col>
                      <v-col cols="4">
                        <v-btn icon @click="showMenu=!showMenu">
                              <v-icon>mdi-menu</v-icon>
                        </v-btn>
                        <v-menu attach=".logginUser" top right v-model="showMenu">
                          <v-list>
                            <v-list-item>
                              <v-btn class="justify-content-start" text width="100%" :to="'/userprofile/'+ loggedInUser.username+'/timeline' ">
                                Profile
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn class="justify-content-start" text width="100%" @click="showMenu=false; openChangePass=true">
                                Thay đổi mật khẩu
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn class="justify-content-start" text width="100%" @click="logout">Đăng xuất</v-btn>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-dialog width="500" v-model="openChangePass">
                                <v-card>
                                  <v-card-title class="headline grey lighten-2" primary-title>
                                    Thay đổi mật khẩu
                                  </v-card-title>

                                  <v-card-text>
                                    <change-password></change-password>
                                  </v-card-text>

                                </v-card>
                              </v-dialog>
                      </v-col>
                      </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
          </v-col>
    </v-col>
  </v-row>
</template>

<script>
import {authComputed} from '@/helpers'
import ChangePassword from '@/views/ResetPassword.vue'

export default {
  data () {
    return {
      showMenu: false,
      openChangePass: false,
      items: [
        
      ],
    }
  },
  components:{
    'change-password': ChangePassword
  },
  computed:{
    ...authComputed
  },
  methods:{
    logout(){
      this.$store.dispatch("user/logout")
    }
  }, 
  mounted(){
    this.items = [
        // { title: 'Trang chủ', icon: 'mdi-home',link:'/'},
        // { title: 'Thông báo', icon: 'mdi-bell', link:'/notifications', hasBadges: true },
        // { title: 'Tìm đồng đội', icon: 'mdi-image',link:'/myphoto' },
        { title: 'Tìm lịch trình', icon: 'mdi-magnify',link:'/find-schedule', requireAuth: false },
        { title: 'Chia sẻ trải nghiệm', icon: 'mdi-notebook',link:'/review', requireAuth: true },
        { title: 'Danh sách của tôi', icon: 'mdi-view-list',link:'/my-list',requireAuth: true },
        { title: 'Hòm thư góp ý', icon: 'mdi-email',link:'/about', requireAuth: true },

    ]
  },
}
</script>

<style scoped>
.menu a{
  text-decoration: none;
}
</style>