<template>
<div>
      <v-container class="fill-height" >
        <v-row no-gutters>
          <v-col md="3" lg="3" xl="4">
              <nav-bar ></nav-bar>
          </v-col>
          <v-col md="12" lg="9" xl="8" style="min-height:100vh">
            <router-view></router-view>
          </v-col>
        </v-row>
        
      </v-container>
      <c-footer></c-footer>
</div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

export default {
  components: {
    'c-footer': Footer,
    'nav-bar': NavBar,
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.row{
  min-height: 100%;
}
.fill-height{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
