<template>
    <div @click.stop v-if="show" class="ma-2">
        <v-textarea auto-grow autofocus counter="500" :rows="height"  flat outlined
            v-model="comment" 
            @input="$emit('input',comment)"
            @keyup.shift.enter="send">
            <template v-slot:append>
                    <v-btn
                    :disabled="!validComment"
                    icon x-small color="primary"
                    @click="send">
                    <v-icon>mdi-send</v-icon>
                </v-btn>
            </template>
        </v-textarea>
    </div>
</template>
<script>
export default {
    props:{
        show:{
            type: Boolean,
            default: ()=>false
        },
        height: {
            type: Number,
            default: ()=>1
        },
    },
    data(){
        return {
            comment:''
        }
    },
    computed:{
        validComment(){
            return (this.comment.trim().length>0 && this.comment.trim().length<=500)
        }
    },
    methods:{
        send(){
            if(!this.validComment){
                return;
            }
            this.$emit("send")
            this.comment  = ''
        },
    }
}
</script>
<style scoped>

</style>