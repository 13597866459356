import Vue from 'vue'
import Vuex from 'vuex'
import {UserSession} from '@/store/usersession.js'
import {ProgessBar} from '@/store/loading.js'
import {Notifications} from '@/store/notification.js'
import {Global} from '@/store/global.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: UserSession,
    progressBar: ProgessBar,
    notifications: Notifications,
    global: Global
  }
})
