<template>
  <v-app>
    <v-content class="body">
      <component :is="layout">
      </component>
    </v-content>
  </v-app>
</template>

<script>
const defaultLayout = "default"

export default {
  data: () => ({
  }),
  computed:{
    layout(){
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  mounted(){
  }
};
</script>

<style scoped>
.body{
  /* font-size: 14px;
  font-family: NotoSans-Regular,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
  line-height: 1.5385615384; */
  background-color: #e9ebee
}
</style>